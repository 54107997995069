import React from 'react';
import Checkout from "./Сheckout";
import {connect} from "react-redux";
import ApiService from '../../../api/api.js';
import ApiNovaPoshta from "../../../api/apiNovaPoshta";
import {
    changeOrderFormData, changeOrderFormWFPData,
    checkPromocodeOrder,
    checkPromocodeOrderThunk, getCitiesThunk, getDirectoryDataThunk,
    getRegionsThunk,
    getWarehouseThunk,
    sendOrderDataThunk
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class CheckoutContainer extends React.Component {

    constructor(props){
        super(props);
        this.user = true;
        this.state = {
            regions: []
        }
    }

    apiService = new ApiService();
    apiNovaPoshta = new ApiNovaPoshta();

    getTotals() {
        let in_stock = this.props.products ? this.props.products.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let pre_order = this.props.productsPreOrder ? this.props.productsPreOrder.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let total = in_stock + pre_order;

        return {
            in_stock: in_stock,
            pre_order: pre_order,
            total: total
        }
    }

    componentDidMount() {
        this.props.directory();
        this.props.getRegions();

        if(this.props.uri.match.params.types === 'preorder' && !this.props.isAuth) {
            this.props.changeValue('registerMe', true);
        } else {
            this.props.changeValue('registerMe', false);
        }
        if(this.props.user && this.props.user.id && this.user) {
            this.user = false;
            this.props.changeValue('name', this.props.user.name);
            this.props.changeValue('surname', this.props.user.surname);
            this.props.changeValue('email', this.props.user.email);
            this.props.changeValue('phone', this.props.user.phone);
            if(this.props.user.companies && this.props.user.companies[0]) {
                this.props.changeValue('company_name', this.props.user.companies[0].name );
                this.props.changeValue('company_code', this.props.user.companies[0].code);
                this.props.changeValue('company_address', this.props.user.companies[0].address);
                this.props.changeValue('company_number_pdv', this.props.user.companies[0].pdv_code);
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props.user && this.props.user.id && this.user) {
            this.user = false;
            this.props.changeValue('name', this.props.user.name);
            this.props.changeValue('surname', this.props.user.surname);
            this.props.changeValue('email', this.props.user.email);
            this.props.changeValue('phone', this.props.user.phone);
            if(this.props.user.companies && this.props.user.companies[0]) {
                this.props.changeValue('company_name', this.props.user.companies[0].name );
                this.props.changeValue('company_code', this.props.user.companies[0].code);
                this.props.changeValue('company_address', this.props.user.companies[0].address);
                this.props.changeValue('company_number_pdv', this.props.user.companies[0].pdv_code);
            }

        }
        if (nextProps.form.region_id && this.props.form.region_id !== nextProps.form.region_id) {
            this.props.getCities(nextProps.form.region_id);
        }
        if (nextProps.form.city_id && this.props.form.city_id !== nextProps.form.city_id) {
            this.props.getWarehouses(nextProps.form.city_id);
        }
    }

    render() {
        return <Checkout form={this.props.form}
                         form_wfp={this.props.form_wfp}
                         changeValue={this.props.changeValue}
                         products={this.props.uri.match.params.types === 'preorder' ? this.props.productsPreOrder : this.props.products}
                         sendOrder={this.props.sendOrder}
                         type={this.props.uri.match.params.types}
                         checkPromocode={this.props.checkPromocode}
                         getCities={this.props.getCities}
                         getWarehouses={this.props.getWarehouses}
                         user={this.props.user}
                         isAuth={this.props.isAuth}
                         totals={this.getTotals()}
                         directoryData={this.props.directoryData}
                         npRegions={this.state.regions}
                         getNpRegions={this.getNpRegions}
                         getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.order,
        form_wfp: state.main.order_wfp,
        products: state.main.cart.products,
        productsPreOrder: state.main.cart.productsPreOrder,
        user: state.auth.user,
        isAuth: state.auth.isAuth,
        directoryData: state.main.directoryData,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeOrderFormData(input, value)),
        changeValueWFP: (input, value) => dispatch(changeOrderFormWFPData()),
        sendOrder: (order, products, type, user_id, gateway_id) => dispatch(sendOrderDataThunk(order, products, type, user_id, gateway_id)),
        checkPromocode: (promocode) => dispatch(checkPromocodeOrderThunk(promocode)),
        getRegions: () => dispatch(getRegionsThunk()),
        getCities: (region, value) => dispatch(getCitiesThunk(region, value)),
        getWarehouses: (city, value) => dispatch(getWarehouseThunk(city, value)),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(CheckoutContainer);
