import React, {useState} from 'react';
import './Сheckout.css'
import Select from 'react-select'
import {Link, Redirect} from "react-router-dom";
import * as PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import {formatSum, getRegionsThunk} from "../../../store/reducers/MainReducer";
import CalculateWindowContainer from "../CalculateWindow/CalculateWindowContainer";
import {useDispatch, useSelector} from "react-redux";
import LangLink from "../../Common/LangLink";
import {SeoTagsStatic} from "../../SeoTagsStatic";
import {PaymentsGateways} from "../PaymentsGateways/PaymentsGateways";
import ApiService from '../../../api/api';

const apiService = new ApiService();

const Checkout = (props) => {
    const [gatewayId, setGatewayId] = useState(1);
    const franchisees = useSelector((state) => state.auth.user && state.auth.user.franchisees);
    const [needValidate, setNeedValidate] = useState(false);

    const getRegions = () => {
        let regions = props.directoryData.regions && props.directoryData.regions.map(e => {
            return {
                value: e.ref,
                label: e.description
            }
        });
        return regions || [];
    }
    const getDistricts = (region) => {
        let districts = props.form.region && props.directoryData.districts && props.directoryData.districts
            .filter(e => Number(e.id_region) === Number(props.form.region))
            .map(e => {
                return {
                    value: e.id,
                    label: e.name
                }
            });
        return districts;
    }
    const getCities = (district) => {
        let citi = props.form.region && props.directoryData.cities && props.directoryData.cities
            // .filter(e => Number(e.id_region) === Number(props.form.region))
            .map(e => {
                const regex = /\((.*)\)/;
                return {
                    value: e.ref,
                    label: e.description.replace(regex, '')
                }
            });
        return citi || [];
    }

    const getWarehouse = (city) => {
        let citi = props.form.city && props.directoryData.np_warehouse && props.directoryData.np_warehouse
            .map(e => {
                return {
                    value: e.ref,
                    label: e.description
                }
            });
        return citi || [];
    }

    return (
        <>
            <SeoTagsStatic page={'checkout'}/>
            <div className={'checkout'}>
                <div className="row">
                    <div className="col-md-12 checkout-header-title">
                        <h3>{props.getTranslate('front_checkout','created_order_title_part_1')} {props.type === 'preorder' ? props.getTranslate('front_checkout','created_order_title_part_2_preorder') : props.getTranslate('front_checkout','created_order_title_part_2_order')}</h3>
                        {franchisees && <span className={'checkout-franchisees-label-container'}>Аккаунт Франчезі</span>}
                    </div>
                </div>
                <form onSubmit={(event) => {
                    setNeedValidate(true);
                    event.preventDefault();
                    if (
                        (props.form && props.form.region_id && props.form.city_id) &&
                        (Number(props.form.delivery) === 2 ? !!(props?.form?.np_warehouse) : !!(props?.form?.street && props?.form?.street_home))
                    ) {
                        props.sendOrder(props.form, props.products, props.form && props.form.asPartner ? (props.type === 'preorder' ? 4 : 3) : (props.type === 'preorder' ? 2 : 1), props.user ? props.user.id : 0, gatewayId);
                    }
                }}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className={'card'}>
                                <div className="card-header">
                                    <h4>{props.getTranslate('front_checkout','order_detail_title')}</h4>
                                </div>
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-6">
                                        <input type="text"
                                               className="form-control"
                                               placeholder={props.getTranslate('front_checkout','checkout_input_name')}
                                               value={props.form && props.form.name ? props.form.name : ''}
                                               onChange={(event) => props.changeValue('name', event.target.value)}
                                               required={'required'}
                                        />
                                    </div>
                                    <div className="input-group col-md-6">
                                        <input type="text"
                                               className="form-control"
                                               placeholder={props.getTranslate('front_checkout','checkout_input_surname')}
                                               value={props.form && props.form.surname ? props.form.surname : ''}
                                               onChange={(event) => props.changeValue('surname', event.target.value)}
                                               required={'required'}
                                        />
                                    </div>
                                </div>
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-6">
                                        <input type="text"
                                               className="form-control"
                                               placeholder={props.getTranslate('front_checkout','checkout_input_middle_name')}
                                               value={props.form && props.form.middle_name ? props.form.middle_name : ''}
                                               onChange={(event) => props.changeValue('middle_name', event.target.value)}
                                               required={Number(props.form.delivery) === 1 ? 'required' : ""}
                                        />
                                    </div>
                                </div>
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-6">
                                        <input type="email"
                                               className="form-control"
                                               placeholder="Email"
                                               value={props.form && props.form.email ? props.form.email : ''}
                                               onChange={(event) => props.changeValue('email', event.target.value)}
                                               required={'required'}
                                        />
                                    </div>
                                    <div className="input-group col-md-6">
                                        <InputMask mask="+380 99 999 9999"
                                                   maskChar=" "
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_input_phone')}
                                                   value={props.form && props.form.phone ? props.form.phone : ''}
                                                   onChange={(event) => props.changeValue('phone', event.target.value)}
                                                   required={'required'}
                                        />
                                    </div>
                                </div>
                                <div className={'card-body row checkout_sub_title'}>
                                    <h4 className={"checkout_sub_title_h4 col-md-12"}>{props.getTranslate('front_checkout','checkout_delivery_address_title')}</h4>
                                </div>
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-6">
                                        <Select value={props.form && props.form.region_id ? props.form.region_id.label : ''}
                                                onChange={(selectedOption) => {
                                                    props.changeValue('region', selectedOption.label);
                                                    props.changeValue('region_id', selectedOption.value);
                                                    setNeedValidate(true);
                                                }}
                                                placeholder={props.getTranslate('front_checkout','checkout_input_region')}
                                                className={'form-select-container'}
                                                required
                                                options={getRegions()}/>
                                        {needValidate && !props?.form?.region_id && (
                                            <span style={{ color: 'red' }}>Поле повинно бути заповненим!</span>
                                        )}        
                                    </div>
                                    <div className="input-group col-md-6">
                                        <Select value={props.form && props.form.city_id ? props.form.city_id.label : ''}
                                                onChange={(selectedOption) => {
                                                    props.changeValue('city', selectedOption.label);
                                                    props.changeValue('city_id', selectedOption.value);
                                                    setNeedValidate(true);
                                                }}
                                                onInputChange={(value) => {
                                                    props.getCities(props.form.region_id, value);
                                                }}
                                                placeholder={props.getTranslate('front_checkout','checkout_input_city')}
                                                isSearchable
                                                className={'form-select-container'}
                                                required={'required'}
                                                options={getCities()}/>
                                        {needValidate && !props?.form?.city_id && (
                                            <span style={{ color: 'red' }}>Поле повинно бути заповненим!</span>
                                        )}   
                                    </div>
                                </div>
                                <div className={'card-body row'}>
                                    {Number(props.form.delivery) === 2 ?
                                        <>
                                            <div className="input-group col-md-12">
                                                <Select value={props.form && props.form.np_warehouse_id ? props.form.np_warehouse_id.label : ''}
                                                        onChange={(selectedOption) => {
                                                            props.changeValue('np_warehouse', selectedOption.label);
                                                            props.changeValue('np_warehouse_id', selectedOption.value);
                                                            setNeedValidate(true);
                                                        }}
                                                        placeholder={props.getTranslate('front_checkout','checkout_input_np_warehouse')}
                                                        className={'form-select-container'}
                                                        onInputChange={(value) => {
                                                            props.getWarehouses(props.form.city_id, value);
                                                        }}
                                                        required={'required'}
                                                        isSearchable
                                                        options={getWarehouse()}/>
                                                {needValidate && !props?.form?.np_warehouse_id && (
                                                    <span style={{ color: 'red' }}>Поле повинно бути заповненим!</span>
                                                )}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="input-group col-md-4">
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder={props.getTranslate('front_checkout','checkout_input_street')}
                                                       value={props.form && props.form.street ? props.form.street : ''}
                                                       onChange={(event) => props.changeValue('street', event.target.value)}
                                                       required={'required'}
                                                />
                                            </div>
                                            <div className="input-group col-md-2">
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="#"
                                                       value={props.form && props.form.street_home ? props.form.street_home : ''}
                                                       onChange={(event) => props.changeValue('street_home', event.target.value)}
                                                       required={'required'}
                                                />
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-12">
                                        <div className="icheck-primary">
                                            <input type="checkbox"
                                                   id="isFizPayment"
                                                   value={props.form && props.form.companyPayment ? props.form.companyPayment : ''}
                                                   onChange={(event) => props.changeValue('companyPayment', event.target.checked)}
                                                   checked={props.form && props.form.companyPayment ? 'checked' : ''}
                                            />
                                            <label htmlFor="isFizPayment">
                                                {props.getTranslate('front_checkout','checkout_payment_is_not_company')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {!props.form.companyPayment ? <>
                                    <div className={'card-body row'}>
                                        <div className="input-group col-md-6">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_input_name_company')}
                                                   value={props.form && props.form.company_name ? props.form.company_name : ''}
                                                   onChange={(event) => props.changeValue('company_name', event.target.value)}
                                                   required={'required'}
                                            />
                                        </div>
                                        <div className="input-group col-md-6">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_input_code_company')}
                                                   value={props.form && props.form.company_code ? props.form.company_code : ''}
                                                   onChange={(event) => props.changeValue('company_code', event.target.value)}
                                                   required={'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={'card-body row'}>
                                        <div className="input-group col-md-6">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_input_address_company')}
                                                   value={props.form && props.form.company_address ? props.form.company_address : ''}
                                                   onChange={(event) => props.changeValue('company_address', event.target.value)}
                                                   required={'required'}
                                            />
                                        </div>
                                        <div className="input-group col-md-6">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_input_code_pdv_company')}
                                                   value={props.form && props.form.company_number_pdv ? props.form.company_number_pdv : ''}
                                                   onChange={(event) => props.changeValue('company_number_pdv', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </> : ''}
                                <div className={'card-body row'}>
                                    <div className="input-group col-md-12">
                                    <textarea rows={4}
                                              className="form-control"
                                              placeholder={props.getTranslate('front_checkout','checkout_input_description')}
                                              value={props.form && props.form.comment ? props.form.comment : ''}
                                              onChange={(event) => props.changeValue('comment', event.target.value)}
                                    />
                                    </div>
                                </div>
                                {!props.isAuth &&
                                    <div className={'card-body row'}>
                                        <div className="input-group col-md-12">
                                            <div className="icheck-primary">
                                                <input type="checkbox"
                                                       id="remember"
                                                       value={props.form && props.form.registerMe ? props.form.registerMe : ''}
                                                       onChange={(event) => {
                                                           if (props.type !== 'preorder') {
                                                               props.changeValue('registerMe', event.target.checked)
                                                           }
                                                       }}
                                                       checked={props.form && props.form.registerMe ? 'checked' : ''}
                                                />
                                                <label htmlFor="remember">
                                                    {props.getTranslate('front_checkout','checkout_register_me_title')}
                                                </label>/<LangLink className={"checkout_link_color"} to={"/login"}>{props.getTranslate('front_checkout','checkout_login_customer')}</LangLink>
                                            </div>
                                        </div>
                                    </div>}
                                {(props.form && props.form.registerMe) || (props.type === 'preorder' && !props.isAuth) ?
                                    <div className={'card-body row'}>
                                        <div className="input-group col-md-6">
                                            <input type="password"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_password')}
                                                   value={props.form && props.form.password ? props.form.password : ''}
                                                   onChange={(event) => props.changeValue('password', event.target.value)}
                                                   required={'required'}
                                            />
                                        </div>
                                        <div className="input-group col-md-6">
                                            <input type="password"
                                                   className="form-control"
                                                   placeholder={props.getTranslate('front_checkout','checkout_password_check')}
                                                   value={props.form && props.form.password_check ? props.form.password_check : ''}
                                                   onChange={(event) => props.changeValue('password_check', event.target.value)}
                                                   required={'required'}
                                            />
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="col-md-4">
                            {props.form.asPartner ? ''
                                : <div className={'card'}>
                                    <div className="card-header">
                                        <h4>{props.getTranslate('front_checkout','checkout_payments_method')}:</h4>
                                    </div>
                                    <div className={'card-body row payments'}>
                                        <div className="input-group col-md-12">
                                            <input type="radio"
                                                   className="form-control"
                                                   name={'payment'}
                                                   id={'payment_r'}
                                                   value={props.form && props.form.payment ? props.form.payment : ''}
                                                   onChange={() => props.changeValue('payment', 1)}
                                                   checked={props.form && props.form.payment === 1 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'payment_r'}>{props.getTranslate('front_checkout','checkout_payment_method_invoice')}</label>
                                        </div>
                                    </div>
                                    {props.type !== 'preorder' && <div className={'card-body row payments'}>
                                        <div className="input-group col-md-12">
                                            <input type="radio"
                                                   className="form-control"
                                                   id={'payment_v'}
                                                   name={'payment'}
                                                   value={props.form && props.form.payment ? props.form.payment : ''}
                                                   onChange={() => props.changeValue('payment', 2)}
                                                   checked={props.form && props.form.payment === 2 ? 'checked' : ''}
                                            />
                                            <label htmlFor={'payment_v'}>{props.getTranslate('front_checkout','checkout_payment_method_card')}</label>
                                        </div>
                                        {props.form && props.form.payment === 2
                                            ? <div className="input-group col-md-12">
                                                <PaymentsGateways gateway_id={gatewayId} setGateway={setGatewayId}/>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    }
                                </div>
                            }

                            <div className={'card'}>
                                <div className="card-header">
                                    <h4>{props.getTranslate('front_checkout','checkout_delivery_method')}:</h4>
                                </div>
                                <div className={'card-body row payments'}>
                                    <div className="input-group col-md-12">
                                        <input type="radio"
                                               className="form-control"
                                               id={'delivery_n'}
                                               name={'delivery'}
                                               value={props.form && props.form.delivery ? props.form.delivery : ''}
                                               onChange={() => props.changeValue('delivery', 2)}
                                               checked={props.form && props.form.delivery === 2 ? 'checked' : ''}
                                        />
                                        <label htmlFor={'delivery_n'}>{props.getTranslate('front_checkout','checkout_delivery_method_np')}</label>
                                    </div>
                                </div>
                                <div className={'card-body row payments'}>
                                    <div className="input-group col-md-12">
                                        <input type="radio"
                                               className="form-control"
                                               name={'delivery'}
                                               id={'delivery_k'}
                                               value={props.form && props.form.delivery ? props.form.delivery : ''}
                                               onChange={() => props.changeValue('delivery', 1)}
                                               checked={props.form && props.form.delivery === 1 ? 'checked' : ''}
                                        />
                                        <label htmlFor={'delivery_k'}>{props.getTranslate('front_checkout','checkout_delivery_method_courier')}</label>
                                    </div>
                                </div>
                            </div>
                            {props.form.asPartner ? ''
                                :
                                <div className={'card'}>
                                    <div className="card-header">
                                        <h4>{props.getTranslate('front_checkout','checkout_promo_code_title')}: {props.form.promocodeDiscount ?
                                            <i className="fas fa-check text-success"></i> : ''} </h4>
                                    </div>

                                    <div className={'card-body row'}>
                                        <div className="input-group col-md-12">
                                            <div className="input-group">
                                                <InputMask mask="****-****-****-****"
                                                           maskChar=" "
                                                           className="form-control"
                                                           placeholder="XXXX-XXXX-XXXX-XXXX"
                                                           value={props.form && props.form.promocode ? props.form.promocode : ''}
                                                           onChange={(event) => {
                                                            debugger;
                                                               props.changeValue('promocode', event.target.value);
                                                               props.checkPromocode(event.target.value);
                                                           }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={'card'}>
                                <CalculateWindowContainer type={props.type === 'preorder'} products={props.products}
                                                          full={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={'card'}>
                                <div className={'card-body row'}>
                                    <div className="icheck-primary col-md-10">
                                        <input type="checkbox"
                                               id="rules"
                                               value={props.form && props.form.accept ? props.form.accept : ''}
                                               onChange={(event) => props.changeValue('accept', event.target.checked)}
                                               checked={props.form && props.form.accept ? 'checked' : ''}
                                               required={'required'}
                                        />
                                        <label htmlFor="rules">
                                            {props.getTranslate('front_checkout','checkout_i_agree_title')}
                                        </label>
                                    </div>
                                    <div className="checkout_button col-md-2">
                                        <button type={'submit'}>{props.getTranslate('front_checkout','checkout_continue_title')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form method="post" action="https://secure.wayforpay.com/pay" accept-charset="utf-8" id={'way-for-pay-form'}>
                    <input
                        name="merchantAccount"
                        // value="test_merch_n1"
                        value={props.form_wfp && props.form_wfp.merchantAccount ? props.form_wfp.merchantAccount : ''}
                    />
                    <input
                        name="merchantAuthType"
                        // value="SimpleSignature"
                        value={props.form_wfp && props.form_wfp.merchantAuthType ? props.form_wfp.merchantAuthType : ''}
                    />
                    <input
                        name="merchantDomainName"
                        // value="www.market.ua"
                        value={props.form_wfp && props.form_wfp.merchantDomainName ? props.form_wfp.merchantDomainName : ''}
                    />
                    <input
                        name="merchantSignature"
                        // value="b95932786cbe243a76b014846b63fe92"
                        value={props.form_wfp && props.form_wfp.merchantSignature ? props.form_wfp.merchantSignature : ''}
                    />
                    <input
                        name="orderReference"
                        // value="DH783023"
                        value={props.form_wfp && props.form_wfp.orderReference ? props.form_wfp.orderReference : ''}
                    />
                    <input
                        name="orderDate"
                        // value="1415379863"
                        value={props.form_wfp && props.form_wfp.orderDate ? props.form_wfp.orderDate : ''}
                    />
                    <input
                        name="amount"
                        // value="1547.36"
                        value={props.form_wfp && props.form_wfp.amount ? props.form_wfp.amount : ''}
                    />
                    <input
                        name="currency"
                        // value="UAH"
                        value={props.form_wfp && props.form_wfp.currency ? props.form_wfp.currency : ''}
                    />
                    <input
                        name="orderTimeout"
                        // value="49000"
                        value={props.form_wfp && props.form_wfp.orderTimeout ? props.form_wfp.orderTimeout : ''}
                    />
                    { props?.products?.map(e => {
                        return (
                            <>
                                <input
                                    name="productName[]"
                                    // value="Процессор Intel Core i5-4670 3.4GHz"
                                    value={e ? e.name : ''}
                                />
                                {/*<input name="productName[]" value="Память Kingston DDR3-1600 4096MB PC3-12800"/>*/}
                                {/*<input name="productPrice[]" value="1000"/>*/}
                                <input
                                    name="productPrice[]"
                                    // value="547.36"
                                    value={e ? Number(e.price).toFixed(2) : ''}
                                />
                                <input
                                    name="productCount[]"
                                    // value="1"
                                    value={e ? e.count : ''}
                                />
                            </>
                        )
                    })}
                    {/*<input*/}
                    {/*    name="productName[]"*/}
                    {/*    // value="Процессор Intel Core i5-4670 3.4GHz"*/}
                    {/*    value={props.form_wfp && props.form_wfp.productName ? props.form_wfp.productName : ''}*/}
                    {/*/>*/}
                    {/*/!*<input name="productName[]" value="Память Kingston DDR3-1600 4096MB PC3-12800"/>*!/*/}
                    {/*/!*<input name="productPrice[]" value="1000"/>*!/*/}
                    {/*<input*/}
                    {/*    name="productPrice[]"*/}
                    {/*    // value="547.36"*/}
                    {/*    value={props.form_wfp && props.form_wfp.productPrice ? props.form_wfp.productPrice : ''}*/}
                    {/*/>*/}
                    {/*<input*/}
                    {/*    name="productCount[]"*/}
                    {/*    // value="1"*/}
                    {/*    value={props.form_wfp && props.form_wfp.productCount ? props.form_wfp.productCount : ''}*/}
                    {/*/>*/}
                    {/*<input name="productCount[]" value="1"/>*/}
                    <input
                        name="clientFirstName"
                        // value="Вася"
                        value={props.form_wfp && props.form_wfp.clientFirstName ? props.form_wfp.clientFirstName : ''}
                    />
                    <input

                        name="clientLastName"
                        // value="Пупкин"
                        value={props.form_wfp && props.form_wfp.clientLastName ? props.form_wfp.clientLastName : ''}
                    />
                    <input
                        name="clientAddress"
                        // value="пр. Гагарина, 12"
                        value={props.form_wfp && props.form_wfp.clientAddress ? props.form_wfp.clientAddress : ''}
                    />
                    <input
                        name="clientCity"
                        // value="Днепропетровск"
                        value={props.form_wfp && props.form_wfp.clientCity ? props.form_wfp.clientCity : ''}
                    />
                    <input
                        name="clientEmail"
                        // value="some@mail.com"
                        value={props.form_wfp && props.form_wfp.clientEmail ? props.form_wfp.clientEmail : ''}
                    />
                    <input
                        name="defaultPaymentSystem"
                        // value="card"
                        value={props.form_wfp && props.form_wfp.defaultPaymentSystem ? props.form_wfp.defaultPaymentSystem : ''}
                    />
                </form>
            </div>
        </>


    )
}

export default Checkout;
