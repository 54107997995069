import ApiService from '../../api/api.js';

const apiService = new ApiService();

const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
const TOGGLE_ACTIVE_MENU = 'TOGGLE_ACTIVE_MENU';
const SET_PRODUCTS_DETAIL_DATA = 'SET_PRODUCTS_DETAIL_DATA';
const SET_PRODUCTS_DETAIL_TAB_ACTIVE = 'SET_PRODUCTS_DETAIL_TAB_ACTIVE';
const TOGGLE_MODAL_CART = 'TOGGLE_MODAL_CART';
const ADD_CART_PRODUCT = 'ADD_CART_PRODUCT';
const TOGGLE_ALERT_WINDOW = 'TOGGLE_ALERT_WINDOW';
const SET_RELATED_PRODUCTS_DATA = 'SET_RELATED_PRODUCTS_DATA';
const SET_SEARCHED_PRODUCTS_DATA = 'SET_SEARCHED_PRODUCTS_DATA';
const SET_SEARCHED_API_PRODUCTS_DATA = 'SET_SEARCHED_API_PRODUCTS_DATA';
const SET_CATEGORY_PRODUCTS_DATA = 'SET_CATEGORY_PRODUCTS_DATA';
const CHANGE_PRODUCTS_CART_DATA = 'CHANGE_PRODUCTS_CART_DATA';
const DELETE_PRODUCTS_CART_DATA = 'DELETE_PRODUCTS_CART_DATA';
const ADD_CART_PRODUCT_PREORDER = 'ADD_CART_PRODUCT_PREORDER';
const CHANGE_PRODUCTS_PRE_ORDER_CART_DATA = 'CHANGE_PRODUCTS_PRE_ORDER_CART_DATA';
const DELETE_PRODUCTS_PRE_ORDER_CART_DATA = 'DELETE_PRODUCTS_PRE_ORDER_CART_DATA';
const SET_CART_ALL_DATA = 'SET_CART_ALL_DATA';
const CHANGE_REGISTRATION_FORM_VALUE = 'CHANGE_REGISTRATION_FORM_VALUE';
const TOGGLE_ACCOUNT_ACTIVE_TAB = 'TOGGLE_ACCOUNT_ACTIVE_TAB';
const SET_ACTIVE_PRODUCT_CATEGORY = 'SET_ACTIVE_PRODUCT_CATEGORY';
const SET_CATEGORY_TREE_DATA = 'SET_CATEGORY_TREE_DATA';
const CHANGE_PRODUCTS_CATEGORY_FILTERS = 'CHANGE_PRODUCTS_CATEGORY_FILTERS';
const SET_MANUFACTURERS_DATA = 'SET_MANUFACTURERS_DATA';
const CHANGE_INPUTS_PRICE_RANGE = 'CHANGE_INPUTS_PRICE_RANGE';
const SET_FILTERS_ATTRIBUTES_DATA = 'SET_FILTERS_ATTRIBUTES_DATA';
const CHANGE_SEARCH_INPUT_DATA = 'CHANGE_SEARCH_INPUT_DATA';
const CHANGE_ORDER_DATA_FORM = 'CHANGE_ORDER_DATA_FORM';
const CHANGE_ORDER_DATA_FORM_WFP = 'CHANGE_ORDER_DATA_FORM_WFP';
const SET_CONFIRM_ORDER_DATA = 'SET_CONFIRM_ORDER_DATA';
const GET_LANGUAGES_DATA = 'GET_LANGUAGES_DATA';
const SET_PAGINATION_COUNT_DATA = 'SET_PAGINATION_COUNT_DATA';
const DELETE_PRODUCTS_CART_DATA_ALL = 'DELETE_PRODUCTS_CART_DATA_ALL';
const SET_DISCOUNTS_CART_DATA = 'SET_DISCOUNTS_CART_DATA';
const SET_CART_TOTALS = 'SET_CART_TOTALS';
const CLEAN_PRODUCTS_FILTERS_CATEGORY_DATA = 'CLEAN_PRODUCTS_FILTERS_CATEGORY_DATA';
const TOGGLE_ORDER_PROFILE_ACTIVE = 'TOGGLE_ORDER_PROFILE_ACTIVE';
const GET_DIRECTORY_DATA = 'GET_DIRECTORY_DATA';
const GET_SPECIALTY_DATA = 'GET_SPECIALTY_DATA';
const CHANGE_PARTNER_ADD_FORM = 'CHANGE_PARTNER_ADD_FORM';
const TOGGLE_MODAL_VIEW_CONTACT = 'TOGGLE_MODAL_VIEW_CONTACT';
const GET_REGIONS_ACS = 'GET_REGIONS_ACS';
const GET_CITIES_ACS = 'GET_CITIES_ACS';
const GET_WAREHOUSES_ACS = 'GET_WAREHOUSES_ACS';
const ADD_PARTNER_PRODUCT_SALE = 'ADD_PARTNER_PRODUCT_SALE';
const DELETE_PARTNER_PRODUCT_SALE = 'DELETE_PARTNER_PRODUCT_SALE';
const COUNT_PARTNER_PRODUCT_SALE = 'COUNT_PARTNER_PRODUCT_SALE';
const SELECT_PARTNER_PRODUCT_SALE = 'SELECT_PARTNER_PRODUCT_SALE';
const CHANGE_CONTACT_ACCOUNT_FORM_VALUE = 'CHANGE_CONTACT_ACCOUNT_FORM_VALUE';
const CHANGE_RESET_PASSWORD_VALUE = 'CHANGE_RESET_PASSWORD_VALUE';
const CHANGE_RESET_PASSWORD_EMAIL_VALUE = 'CHANGE_RESET_PASSWORD_EMAIL_VALUE';
const CHANGE_PARTNER_BONUS_FORM = 'CHANGE_PARTNER_BONUS_FORM';
const CHANGE_LOADER_STATUS = 'CHANGE_LOADER_STATUS';
const CHANGE_LOADER_MESSAGE = 'CHANGE_LOADER_MESSAGE';
const SET_ALL_TRANSLATES_DATA = 'SET_ALL_TRANSLATES_DATA';
const CHANGE_SHOW_COOKIE_STATUS = 'CHANGE_SHOW_COOKIE_STATUS';

export function isMobile() {
    if(window.screen.width <= 769) {
        return true;
    }
}

let initialState = {
    menuItems: [],
    menuActive: null,
    productDetail: null,
    directoryData: [],
    specialtyData: [],
    productDetailTabActive: 'description',
    manufacturers: [],
    orderOpen: 0,
    productsInputsRange: {
        min: 0,
        max: 0
    },
    productsCategoryFilters: {
        price_min: null,
        price_max: null,
        manufacturer: [],
        in_pack: [],
        inStock: [],
        getDiscount: [],
        isNewProduct: [],
        attributes: []
    },
    relatedProducts: [],
    categoryProducts: [],
    categoryTree: [],
    resetPassword: {
        password: null,
        check_password: null,
        success: false,
        fail: false,
        message: null
    },
    resetPasswordEmail: {
        email: null,
        success: false,
        fail: false,
        message: null
    },
    translates: null,
    filtersAttributes: {
        attributes: [],
        price: {
            max: 0,
            min: 0
        }
    },
    searchedProducts: [],
    searchedApiProducts: [],
    confirmOrder: null,
    productsActiveCategory: null,
    searchInput: '',
    cart: {
        isOpen: false,
        products: [],
        productsPreOrder: [],
        discounts: {
            personal: false,
            count: false
        }
    },
    cartTotals: null,
    registrationForm: {
        name: null,
        surname: null,
        middle_name: null,
        phone: null,
        email: null,
        password: null,
        password_check: null,
        login: null,
        regCompany: false,
        company_name: null,
        company_code: null,
        company_address: null,
        company_number_pdv: null,
        newsletter: false,
        captcha: false,
        validEmail: true
    },
    loader: {
        active: true,
        message: null,
        count: 0
    },
    paginationCount: null,
    languages: [],
    order: {
        name: null,
        surname: null,
        middle_name: null,
        email: null,
        phone: null,
        city: null,
        street: null,
        street_home: null,
        region: null,
        district: null,
        comment: null,
        registerMe: null,
        accept: null,
        password: null,
        password_check: null,
        payment: 1,
        delivery: 2,
        companyPayment: true,
        company_name: null,
        company_code: null,
        company_address: null,
        company_number_pdv: null,
        asPartner: false,
        promocode: null,
        promocodeDiscount: null
    },
    order_wfp: {
        merchantAccount: null,
        merchantDomainName: null,
        merchantSignature: null,
        merchantAuthType: 'SimpleSignature',
        orderReference: null,
        orderDate: null,
        currency: 'UAH',
        orderTimeout: 49000,
        productName: null,
        productPrice: null,
        productCount: null,
        amount: null,
        clientFirstName: null,
        clientLastName: null,
        clientAddress: null,
        clientCity: null,
        clientEmail: null,
        defaultPaymentSystem: 'card'
    },
    PartnerBonusForm: {
        bonus: null
    },
    accountActiveTab: 'profile',
    contactAccountForm: {
        theme: '',
        message: '',
        isSend: false
    },
    alert: {
        active: false,
        type: null,
        message: null,

    },
    viewContactModal: {
        isOpen: false
    },
    showCookie: true,
    partnerSaleProducts: [],
    partnerSaleProductChange: null,
    PartnerForm: {
        id_customer: null,
        specialty: null,
        address_register: null,
        pasport_series: null,
        pasport_organization: null,
        pasport_data: null,
        ipn: null,
        bank_name: null,
        bank_code: null,
        bank_iban: null
    }
};

const MainReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MENU_ITEMS:
            return {
                ...state,
                menuItems: action.items,
            }
        case TOGGLE_MODAL_VIEW_CONTACT:
            return {
                ...state,
                viewContactModal: {
                    ...state.viewContactModal,
                    isOpen: action.status,
                    user: action.user
                }
            }
        case TOGGLE_ACTIVE_MENU:
            return {
                ...state,
                menuActive: action.active,
                menu1levelOpen: null
            }
        case SET_ALL_TRANSLATES_DATA:
            return {
                ...state,
                translates: action.translates,
            }
        case CHANGE_LOADER_STATUS:
            let count = action.status ? state.loader.count + 1 : state.loader.count - 1;
            let status = count > 0;
            let message = status ? state.loader.message : null;
            return {
                ...state,
                loader: {
                    ...state.loader,
                    count: count,
                    active: status,
                    message: message
                }
            };
        case CHANGE_LOADER_MESSAGE:
            return {
                ...state,
                loader: {
                    ...state.loader,
                    message: action.message
                }
            };
        case SELECT_PARTNER_PRODUCT_SALE:
            return {
                ...state,
                partnerSaleProductChange: action.id,
            }
        case CHANGE_SHOW_COOKIE_STATUS:
            return {
                ...state,
                showCookie: action.status,
            }
        case CHANGE_RESET_PASSWORD_VALUE:
            let reset_password_form = {...state.resetPassword};
            if(action.input !== 'success' && action.input !== 'fail' && action.input !== 'message') {
                reset_password_form.success = false;
                reset_password_form.fail = false;
                reset_password_form.message = null;
            }
            reset_password_form[action.input] = action.value;
            return {
                ...state,
                resetPassword: reset_password_form,
            }
        case CHANGE_RESET_PASSWORD_EMAIL_VALUE:
            let reset_password_form_email = {...state.resetPasswordEmail};
            if(action.input !== 'success' && action.input !== 'fail' && action.input !== 'message') {
                reset_password_form_email.success = false;
                reset_password_form_email.fail = false;
                reset_password_form_email.message = null;
            }
            reset_password_form_email[action.input] = action.value;
            return {
                ...state,
                resetPasswordEmail: reset_password_form_email,
            }
        case ADD_PARTNER_PRODUCT_SALE:
            let new_product = {
                ...action.product
            };
            new_product.count = 1;
            let add_partner_product = [
                ...state.partnerSaleProducts,
                new_product
            ]
            return {
                ...state,
                partnerSaleProducts: add_partner_product,
                partnerSaleProductChange: null
            }
        case DELETE_PARTNER_PRODUCT_SALE:
            let delete_partner_product = [...state.partnerSaleProducts].filter(e => e.alias !== action.id)
            return {
                ...state,
                partnerSaleProducts: delete_partner_product,
            }
        case COUNT_PARTNER_PRODUCT_SALE:
            let count_partner_product = [...state.partnerSaleProducts].map(e => {
                if(e.alias === action.id) {
                    e.count = action.count && Number(action.count) > 0 ? action.count : 1;
                }
                return e;
            })
            return {
                ...state,
                partnerSaleProducts: count_partner_product,
            }
        case GET_DIRECTORY_DATA:
            return {
                ...state,
                directoryData: {
                    ...state.directoryData,
                    ...action.directory
                },
            }
        case GET_REGIONS_ACS:
            return {
                ...state,
                directoryData: {
                    ...state.directoryData,
                    regions: action.regions
                },
            }
        case GET_CITIES_ACS:
            return {
                ...state,
                directoryData: {
                    ...state.directoryData,
                    cities: action.cities
                },
            }
        case GET_WAREHOUSES_ACS:
            return {
                ...state,
                directoryData: {
                    ...state.directoryData,
                    np_warehouse: action.np_warehouse
                },
            }
        case GET_SPECIALTY_DATA:
            return {
                ...state,
                specialtyData: action.specialty,
            }
        case TOGGLE_ORDER_PROFILE_ACTIVE:
            let order_id = Number(state.orderOpen) === Number(action.id) ? 0 : action.id;
            return {
                ...state,
                orderOpen: order_id
            }
        case SET_PRODUCTS_DETAIL_DATA:
            return {
                ...state,
                productDetail: action.product,
            }
        case SET_PAGINATION_COUNT_DATA:
            return {
                ...state,
                paginationCount: action.count,
            }
        case SET_CART_TOTALS:
            return {
                ...state,
                cartTotals: action.totals,
            }
        case CHANGE_PARTNER_BONUS_FORM:
            let PartnerBonusForm = {...state.PartnerBonusForm};
            PartnerBonusForm[action.input] = action.value;
            return {
                ...state,
                PartnerBonusForm: PartnerBonusForm
            }
        case SET_CONFIRM_ORDER_DATA:
            return {
                ...state,
                confirmOrder: action.order,
            }
        case SET_ACTIVE_PRODUCT_CATEGORY:
            return {
                ...state,
                productsActiveCategory: action.category,
                menuActive: null
            }
        case GET_LANGUAGES_DATA:
            return {
                ...state,
                languages: action.languages,
            }
        case CHANGE_SEARCH_INPUT_DATA:
            return {
                ...state,
                searchInput: action.value
            }
        case SET_FILTERS_ATTRIBUTES_DATA:
            return {
                ...state,
                filtersAttributes: action.filters,
                productsInputsRange: {
                    min: action.filters && action.filters.price ? action.filters.price.min : 0,
                    max: action.filters && action.filters.price ? action.filters.price.max : 0
                },
            }
        case SET_PRODUCTS_DETAIL_TAB_ACTIVE:
            return {
                ...state,
                productDetailTabActive: action.active,
            }
        case SET_CATEGORY_TREE_DATA:
            return {
                ...state,
                categoryTree: action.tree,
            }
        case CHANGE_INPUTS_PRICE_RANGE:
            return {
                ...state,
                productsInputsRange: {
                    ...action.range
                }
            }
        case TOGGLE_MODAL_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    isOpen: action.status
                },
            }
        case SET_DISCOUNTS_CART_DATA:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    discounts: {
                        ...action.discounts
                    }
                },
            }
        case SET_CART_ALL_DATA:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    isOpen: false,
                    products: action.products,
                    productsPreOrder: action.productsPreOrder,
                },
            }
        case ADD_CART_PRODUCT:
            let products_check = [...state.cart.products].filter((e) => Number(e.id) === Number(action.product.id)).length
            let products = products_check ? [...state.cart.products] : [
                ...state.cart.products,
                {...action.product}
            ];
            localStorage.setItem('cart_products', JSON.stringify(products));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    products: products
                },
            }
        case ADD_CART_PRODUCT_PREORDER:
            let products_check_preorder = [...state.cart.productsPreOrder].filter((e) => Number(e.id) === Number(action.product.id)).length
            let products_preorder = products_check_preorder ? [...state.cart.productsPreOrder] : [
                ...state.cart.productsPreOrder,
                {...action.product}
            ];
            localStorage.setItem('cart_pre_order', JSON.stringify(products_preorder));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    productsPreOrder: products_preorder
                },
            }
        case CHANGE_PRODUCTS_CART_DATA:
            let products_change = [...state.cart.products].map((e) => {
                if(Number(e.id) === Number(action.product_id)) {
                    e[action.input] = action.value;
                    return e;
                } else {
                    return e;
                }
            })
            localStorage.setItem('cart_products', JSON.stringify(products_change));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    products: products_change
                },
            }
        case CHANGE_ORDER_DATA_FORM:
            let order = {...state.order};
            order[action.input] = action.value;
            return {
                ...state,
                order: order
            }
        case CHANGE_ORDER_DATA_FORM_WFP:
            let order_wfp = {...state.order_wfp};
            order_wfp[action.input] = action.value;
            return {
                ...state,
                order_wfp: order_wfp
            }
        case CHANGE_CONTACT_ACCOUNT_FORM_VALUE:
            let contact_account_form = {...state.contactAccountForm};
            contact_account_form.isSend = false;
            contact_account_form[action.input] = action.value;
            return {
                ...state,
                contactAccountForm: contact_account_form
            }
        case CHANGE_PRODUCTS_CATEGORY_FILTERS:
            let products_filters_change = {...state.productsCategoryFilters};
            if(action.input === 'price_min' || action.input === 'price_max') {
                products_filters_change[action.input] = action.value;
            }
            else if(action.input !== 'attributes') {
                if(action.types) {
                    products_filters_change[action.input] = [
                        ...products_filters_change[action.input],
                        action.value
                    ];
                } else {
                    products_filters_change[action.input] = [...products_filters_change[action.input]].filter(e => Number(e) !== Number(action.value));
                }
            } else {
                if(action.types) {
                    products_filters_change.attributes = [
                        ...products_filters_change.attributes,
                        {
                           id: action.value.id,
                           value: action.value.value
                        }
                    ]
                } else {
                    products_filters_change.attributes = products_filters_change.attributes.filter(e => {

                        if(Number(e.id) === Number(action.value.id) && Number(e.value) === Number(action.value.value)) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
            }
            return {
                ...state,
                productsCategoryFilters: products_filters_change
            }
        case CLEAN_PRODUCTS_FILTERS_CATEGORY_DATA:
            return {
                ...state,
                productsCategoryFilters: initialState.productsCategoryFilters
            }
        case CHANGE_PRODUCTS_PRE_ORDER_CART_DATA:
            let products_change_preorder = [...state.cart.productsPreOrder].map((e) => {
                if(Number(e.id) === Number(action.product_id)) {
                    e[action.input] = action.value;
                    return e;
                } else {
                    return e;
                }
            })
            localStorage.setItem('cart_pre_order', JSON.stringify(products_change_preorder));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    productsPreOrder: products_change_preorder
                },
            }
        case DELETE_PRODUCTS_CART_DATA:
            let products_delete = [...state.cart.products].filter((e) => Number(e.id) !== Number(action.product_id));
            localStorage.setItem('cart_products', JSON.stringify(products_delete));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    products: products_delete
                },
            }
        case DELETE_PRODUCTS_PRE_ORDER_CART_DATA:
            let products_delete_preorder = [...state.cart.productsPreOrder].filter((e) => Number(e.id) !== Number(action.product_id));
            localStorage.setItem('cart_pre_order', JSON.stringify(products_delete_preorder));
            return {
                ...state,
                cart: {
                    ...state.cart,
                    productsPreOrder: products_delete_preorder
                },
            }
        case DELETE_PRODUCTS_CART_DATA_ALL:
            if(action.types === 1) {
                let products_delete_all = [];
                localStorage.setItem('cart_products', JSON.stringify(products_delete_all));
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        products: products_delete_all
                    },
                }
            }
            if(action.types === 2) {
                let products_delete_all = [];
                localStorage.setItem('cart_pre_order', JSON.stringify(products_delete_all));
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        productsPreOrder: products_delete_all
                    },
                }
            }
            if(action.types === 3 || action.types === 4) {
                let products_delete_all = [];
                localStorage.setItem('cart_products', JSON.stringify(products_delete_all));
                localStorage.setItem('cart_pre_order', JSON.stringify(products_delete_all));
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        products: products_delete_all,
                        productsPreOrder: products_delete_all
                    },
                }
            }


        case TOGGLE_ALERT_WINDOW:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    active: action.active,
                    type: action.type_alert,
                    message: action.message
                },
            }
        case TOGGLE_ACCOUNT_ACTIVE_TAB:
            return {
                ...state,
                accountActiveTab: action.status
            }
        case SET_MANUFACTURERS_DATA:
            return {
                ...state,
                manufacturers: action.manufacturers
            }
        case SET_RELATED_PRODUCTS_DATA:
            return {
                ...state,
                relatedProducts: action.products
            }
        case SET_SEARCHED_PRODUCTS_DATA:
            return {
                ...state,
                searchedProducts: action.products
            }
        case SET_SEARCHED_API_PRODUCTS_DATA:
            return {
                ...state,
                searchedApiProducts: action.products
            }
        case SET_CATEGORY_PRODUCTS_DATA:
            return {
                ...state,
                categoryProducts: action.products
            }
        case CHANGE_REGISTRATION_FORM_VALUE:
            let registrationForm = {...state.registrationForm};
            registrationForm[action.input] = action.value;
            return {
                ...state,
                registrationForm: registrationForm
            };
        case CHANGE_PARTNER_ADD_FORM:
            let PartnerForm = {...state.PartnerForm};
            if(action.input === 'ipn') {
                if(String(action.value).length <= 10) {
                    PartnerForm[action.input] = action.value;
                }
            } else if(action.input === 'bank_iban') {
                if(String(action.value).length <= 34) {
                    PartnerForm[action.input] = action.value;
                }
            } else {
                PartnerForm[action.input] = action.value;
            }
            return {
                ...state,
                PartnerForm: PartnerForm
            }
        default:
            return state
    }
}

export const setMenuItemsAC = (items) => {
    return {
        type: SET_MENU_ITEMS,
        items: items
    }
}
export const changeInputsRangePriceAC = (range) => {
    return {
        type: CHANGE_INPUTS_PRICE_RANGE,
        range: range
    }
}
export const toggleActiveMenuItemAC = (active) => {
    return {
        type: TOGGLE_ACTIVE_MENU,
        active: active
    }
}
export const toggleCartOpenAC = (status) => {
    return {
        type: TOGGLE_MODAL_CART,
        status: status
    }
}
export const addPartnerProductSaleAC = (product) => {
    return {
        type: ADD_PARTNER_PRODUCT_SALE,
        product: product
    }
}
export const deletePartnerProductSaleAC = (id) => {
    return {
        type: DELETE_PARTNER_PRODUCT_SALE,
        id: id
    }
}
export const setTranslatesAC = (translates) => {
    return {
        type: SET_ALL_TRANSLATES_DATA,
        translates: translates
    }
}
export const changeShowCookieStatusAC = (status) => {
    return {
        type: CHANGE_SHOW_COOKIE_STATUS,
        status: status
    }
}
export const countPartnerProductSaleAC = (id, count) => {
    return {
        type: COUNT_PARTNER_PRODUCT_SALE,
        id: id,
        count: count
    }
}
export const changePartnerProductSaleAC = (id, count) => {
    return {
        type: SELECT_PARTNER_PRODUCT_SALE,
        id: id
    }
}
export const changeSearchInputDataAC = (value) => {
    return {
        type: CHANGE_SEARCH_INPUT_DATA,
        value: value
    }
}
export const setCartDiscountsDataAC = (discounts) => {
    return {
        type: SET_DISCOUNTS_CART_DATA,
        discounts: discounts
    }
}
export const cleanProductsFiltersDataAC = () => {
    return {
        type: CLEAN_PRODUCTS_FILTERS_CATEGORY_DATA,
    }
}
export const setCartTotalsAC = (totals) => {
    return {
        type: SET_CART_TOTALS,
        totals: totals
    }
}
export const setProductsActiveCategory = (category) => {
    return {
        type: SET_ACTIVE_PRODUCT_CATEGORY,
        category: category
    }
}
export const getLanguagesDataAC = (languages) => {
    return {
        type: GET_LANGUAGES_DATA,
        languages: languages
    }
}
export const setConfirmOrderDataAC = (order) => {
    return {
        type: SET_CONFIRM_ORDER_DATA,
        confirmOrder: order
    }
}
export const toggleAccountActiveTabAC = (status) => {
    return {
        type: TOGGLE_ACCOUNT_ACTIVE_TAB,
        status: status
    }
}
export const setFiltersAttributesDataAC = (filters) => {
    return {
        type: SET_FILTERS_ATTRIBUTES_DATA,
        filters: filters
    }
}
export const cleanCartProductsTypesAC = (types) => {
    return {
        type: DELETE_PRODUCTS_CART_DATA_ALL,
        types: types
    }
}
export const setCategoryMenuTreeDataAC = (tree) => {
    return {
        type: SET_CATEGORY_TREE_DATA,
        tree: tree
    }
}
export const setCartStorageAllDataAC = (products, productsPreOrder) => {
    return {
        type: SET_CART_ALL_DATA,
        products: products,
        productsPreOrder: productsPreOrder
    }
}
export const setRelatedProductsAC = (products) => {
    return {
        type: SET_RELATED_PRODUCTS_DATA,
        products: products
    }
}
export const setSearchedProductsAC = (products) => {
    return {
        type: SET_SEARCHED_PRODUCTS_DATA,
        products: products
    }
}
export const changeAccountContactFormAC = (input, value) => {
    return {
        type: CHANGE_CONTACT_ACCOUNT_FORM_VALUE,
        input: input,
        value: value
    }
}
export const setManufacturersDataAC = (manufacturers) => {
    return {
        type: SET_MANUFACTURERS_DATA,
        manufacturers: manufacturers
    }
}
export const setPaginationCountDataAC = (count) => {
    return {
        type: SET_PAGINATION_COUNT_DATA,
        count: count
    }
}
export const setCategoryProductsAC = (products) => {
    return {
        type: SET_CATEGORY_PRODUCTS_DATA,
        products: products
    }
}
export const setSearchAPIProductsAC = (products) => {
    return {
        type: SET_SEARCHED_API_PRODUCTS_DATA,
        products: products
    }
}

export const changeLoaderStatusACS = (status) => {
    return {
        type: CHANGE_LOADER_STATUS,
        status: status
    }
}
export const changeLoaderStatusAC = (status) => (dispatch) => {
    if(status) {
        dispatch(changeLoaderStatusACS(status));
    } else {
        setTimeout(function () {
            dispatch(changeLoaderStatusACS(status));
        }, 750);
    }

}

export const changeLoaderMessageAC = (message) => {
    return {
        type: CHANGE_LOADER_MESSAGE,
        message: message
    }
}

export const setAlertModalAC = (active, type, message) => {
    return {
        type: TOGGLE_ALERT_WINDOW,
        active: active,
        type_alert: type,
        message: message
    }
}
export const addCartProductAC = (product) => {
    return {
        type: ADD_CART_PRODUCT,
        product: product
    }
}
export const addCartProductPreOrderAC = (product) => {
    return {
        type: ADD_CART_PRODUCT_PREORDER,
        product: product
    }
}
export const deleteProductsCartAC = (product_id) => {
    return {
        type: DELETE_PRODUCTS_CART_DATA,
        product_id: product_id
    }
}
export const deleteProductsPreOrderCartAC = (product_id) => {
    return {
        type: DELETE_PRODUCTS_PRE_ORDER_CART_DATA,
        product_id: product_id
    }
}
export const changeProductsCartDataAC = (product_id, input, value) => {
    return {
        type: CHANGE_PRODUCTS_CART_DATA,
        product_id: product_id,
        input: input,
        value: value
    }
}
export const changeOrderFormData = (input, value) => {
    return {
        type: CHANGE_ORDER_DATA_FORM,
        input: input,
        value: value
    }
}
export const changePasswordResetValueAC = (input, value) => {
    return {
        type: CHANGE_RESET_PASSWORD_VALUE,
        input: input,
        value: value
    }
}
export const changePasswordResetValueEmailAC = (input, value) => {
    return {
        type: CHANGE_RESET_PASSWORD_EMAIL_VALUE,
        input: input,
        value: value
    }
}
export const changeOrderFormWFPData = (input, value) => {
    return {
        type: CHANGE_ORDER_DATA_FORM_WFP,
        input: input,
        value: value
    }
}
export const changeRegistrationFormAC = (input, value) => {
    return {
        type: CHANGE_REGISTRATION_FORM_VALUE,
        input: input,
        value: value
    }
}
export const changeProductsCategoryFiltersValueAC = (input, value, type) => {
    return {
        type: CHANGE_PRODUCTS_CATEGORY_FILTERS,
        input: input,
        value: value,
        types: type
    }
}
export const changeProductsCartPreOrderDataAC = (product_id, input, value) => {
    return {
        type: CHANGE_PRODUCTS_PRE_ORDER_CART_DATA,
        product_id: product_id,
        input: input,
        value: value
    }
}
export const toggleOrderProfileActiveDataAC = (id) => {
    return {
        type: TOGGLE_ORDER_PROFILE_ACTIVE,
        id: id,
    }
}
export const getDirectoryDataAC = (directory) => {
    return {
        type: GET_DIRECTORY_DATA,
        directory: directory
    }
}
export const getRegionsAC = (regions) => {
    return {
        type: GET_REGIONS_ACS,
        regions: regions
    }
}
export const getCitiesAC = (cities) => {
    return {
        type: GET_CITIES_ACS,
        cities: cities
    }
}
export const getWarehousesAC = (np_warehouse) => {
    return {
        type: GET_WAREHOUSES_ACS,
        np_warehouse: np_warehouse
    }
}
export const getSpecialtyDataAC = (specialty) => {
    return {
        type: GET_SPECIALTY_DATA,
        specialty: specialty
    }
}
export const toggleViewContactModalAC = (status, user) => {
    return {
        type: TOGGLE_MODAL_VIEW_CONTACT,
        status: status,
        user: user
    }
}
export const changePartnerBonusFormValueAC = (input, value) => {
    return {
        type: CHANGE_PARTNER_BONUS_FORM,
        input: input,
        value: value
    }
}
export const createPartnerBonusThunk = (bonus, id_customer) => (dispatch) => {
    apiService.createPartnerBonus(bonus, id_customer)
        .then(res => {
            if(res) {
                window.open(res);
                window.location.href = '/account/partner_bonus';
            }
        })
}
export const setCookieThunk = (value, id_customer) => (dispatch) => {
    apiService.setCookie(value, id_customer)
        .then(res => {
            dispatch(changeShowCookieStatusAC(false));
        })
}
export const checkCookieThunk = (id_customer) => (dispatch) => {
    apiService.checkCookie(id_customer)
        .then(res => {
            if(res) {
                dispatch(changeShowCookieStatusAC(false));
            } else {
                dispatch(changeShowCookieStatusAC(true));
            }
        })
}
export const addCartProductThunk = (product_add) => (dispatch) => {
    let product = {...product_add};
    product.count_max = Number(product.count);
    product.count = 1;
    if(product_add.count === 0 || Number(product_add.count) < 1) {
        dispatch(addCartProductPreOrderAC(product))
    } else {
        dispatch(addCartProductAC(product));
    }
    dispatch(setAlertModalAC(true, 'success', 'Товар ' + product.name + ' y кошику'))
    setTimeout(function () {
        dispatch(setAlertModalAC(false, null, null))
    }, 1500)
}
export const setRelatedProductsDataThunk = () => (dispatch) => {
    // dispatch(changeLoaderStatusAC(true));
    // apiService.getProducts()
     apiService.getProductsHomeRelated()
        .then(res => {
            if(res && res.data) {
                dispatch(setRelatedProductsAC(res.data));
            }
            // dispatch(changeLoaderStatusAC(false));
        })
}
export const setFiltersAttributesDataThunk = (type, data = {}) => (dispatch) => {
    apiService.getFilters(type, data)
        .then(res => {
            dispatch(setFiltersAttributesDataAC(res));
        })
}
export const setCategoryProductsDataThunk = (category, page = 1) => (dispatch) => {
    // dispatch(changeLoaderStatusAC(true));
    apiService.getProductsCategory(category, page)
        .then(res => {
            dispatch(setProductsActiveCategory(category));
            dispatch(setCategoryProductsAC(res.data));
            dispatch(setPaginationCountDataAC(res.count));
            // dispatch(changeLoaderStatusAC(false));
        })
}
export const setSearchedProductsDataThunk = (key, filters = {}, page) => (dispatch) => {
    apiService.getProductsSearch(key, filters, page)
        .then(res => {
            if(res && res.data)
            dispatch(setSearchAPIProductsAC(res.data));
            dispatch(setPaginationCountDataAC(res.count));
        })
}
export const getLanguagesDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    // dispatch(changeLoaderMessageAC('Отримуємо дані мовних версій...'));
    apiService.getLanguages()
        .then(res => {
            dispatch(getLanguagesDataAC(res));
            dispatch(changeLoaderStatusAC(false));
        })
}
export const getFilteredProductsThunk = (filters, page = 1) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true))
    apiService.getProductsFiltered(filters, page)
        .then(res => {
            if(res && res.data) {
                dispatch(setCategoryProductsAC(res.data));
                dispatch(setPaginationCountDataAC(res.count));
                dispatch(changeLoaderStatusAC(false))
            }
        })
}
export const setCategoryTreeDataThunk = () => (dispatch) => {
    apiService.getCategoryTree()
        .then(res => {
            dispatch(setCategoryMenuTreeDataAC(res));
        })
}
export const changeProductsCategoryFilterThunk = (input, value, type) => (dispatch) => {
    dispatch(changeProductsCategoryFiltersValueAC(input, value, type));
}
export const setSearchedroductsDataThunk = () => (dispatch) => {
    apiService.getProducts()
        .then(res => {
            const storage_products = localStorage.getItem('medbuy_searched_products_store') ? JSON.parse(localStorage.getItem('medbuy_searched_products_store')) : [];
            const searched_products = res && res.data && res.data.length > 0 ? res.data.filter(e => { return storage_products.includes(e.alias) }) : [];
            dispatch(setSearchedProductsAC(searched_products));
        })
}
export const setProductDetailTabActive = (active) => {
    return {
        type: SET_PRODUCTS_DETAIL_TAB_ACTIVE,
        active: active
    }
}
export const setProductDetailDataAC = (product) => {
    return {
        type: SET_PRODUCTS_DETAIL_DATA,
        product: product
    }
}
export const getMenuItemsThunk = () => (dispatch) => {
    apiService.getMenuItems()
        .then(res => {
            dispatch(setMenuItemsAC(res))
        });
}
export const createPartnerThunk = (partner) => (dispatch) => {
    apiService.createPartner(partner)
        .then(res => {
            if(res) {
                window.location.href = '/partner_program';
            }
        })
}
export const sendOrderDataThunk = (order, products, type = 1, user_id, gateway_id) => (dispatch, getState) => {
    dispatch(changeLoaderStatusACS(true));
    const payment = gateway_id === 1 
        ? 2 
        : gateway_id === 2 
            ? 3
            : 1;
    apiService.sendOrder({ ...order, payment }, products, type, user_id)
        .then(res => {
            if(res && res.id) {
                dispatch(setConfirmOrderDataAC(res));
                // dispatch(cleanCartProductsTypesAC(type));
                if(res.receipt && !order.asPartner) {
                    window.open(res.receipt);
                }
                if(order.asPartner) {
                    dispatch(changeLoaderStatusACS(false));
                    setTimeout(function () {
                        window.location.href = '/account/partner_orders';
                    }, 500);
                }
                localStorage.setItem('currentOrderSuccessData', JSON.stringify(res));
                if(order && Number(order.payment) === 2) {

                    /// WayForPay Gateway Functionality
                    if (gateway_id === 1) {
                        const orderDate = Date.now();
                        // const orderDate = 1415379863;

                        dispatch(changeOrderFormWFPData('orderReference', 'ORD_' + res.id));
                        dispatch(changeOrderFormWFPData('clientFirstName', res.customer_name));
                        dispatch(changeOrderFormWFPData('clientLastName', res.customer_surname));
                        dispatch(changeOrderFormWFPData('clientAddress', res.street + res.street_house));
                        dispatch(changeOrderFormWFPData('clientCity', res.city));
                        dispatch(changeOrderFormWFPData('clientEmail', res.payment_customer_email));

                        // if(res.products) {
                        //     dispatch(changeOrderFormWFPData('productName', JSON.stringify(res.products.map(e => e.info.name))));
                        //     dispatch(changeOrderFormWFPData('productPrice', JSON.stringify(res.products.map(e => Number(e.price).toFixed(2)))));
                        //     dispatch(changeOrderFormWFPData('productCount', JSON.stringify(res.products.map(e => e.count))));
                        // } else {
                        //     dispatch(changeOrderFormWFPData('productName', 'Замовлення інтернет-магазину MEDBUY №'+res.id));
                        //     dispatch(changeOrderFormWFPData('productPrice', Number(res.total).toFixed(2)));
                        //     dispatch(changeOrderFormWFPData('productCount', 1));
                        // }
                        dispatch(changeOrderFormWFPData('amount', Number(res.total).toFixed(2)));
                        dispatch(changeOrderFormWFPData('orderDate',orderDate));

                        let hashString = '';
                        res.products.forEach(e => {
                            hashString += `${e.name};`
                        })
                        res.products.forEach(e => {
                            hashString += `${e.count};`
                        })
                        res.products.forEach(e => {
                            hashString += `${Number(e.price).toFixed(2)};`
                        })
                        let testHash = hashString.slice(0, -1)

                        apiService.getWFPHash({
                            date: orderDate,
                            name: 'ORD_' + res.id,
                            amount: Number(res.total).toFixed(2),
                            products: testHash
                        })
                            .then(hash => {
                                dispatch(changeOrderFormWFPData('merchantAccount', hash.account));
                                dispatch(changeOrderFormWFPData('merchantDomainName', hash.domain));
                                dispatch(changeOrderFormWFPData('merchantSignature', hash.hash));
                                document.getElementById('way-for-pay-form').submit();
                                dispatch(changeLoaderStatusACS(false));
                            })
                    }

                    /// Portmone Gateway Functionality
                    if (gateway_id === 2) {
                        if (res.id) {
                            window.location.href = 'https://payment.medbuy.world/payment/index_portmone.php?order=' + res.id +'&total=' + res.total;
                        }
                    }

                } else {
                    setTimeout(function () {
                        dispatch(changeLoaderStatusACS(false));
                        window.location.href = '/success';
                    }, 1000);
                }

                return res;
            }
        });
}
export const setProductDetailDataThunk = (id) => (dispatch) => {
    dispatch(changeLoaderStatusACS(true));
    apiService.getProduct(id)
        .then(res => {
            dispatch(setProductDetailDataAC(res))
            dispatch(changeLoaderStatusACS(false));
        });
}
export const getDirectoryDataThunk = () => (dispatch) => {
    apiService.getDirectory()
        .then(res => {
            dispatch(getDirectoryDataAC(res));
        })
}
export const getRegionsThunk = (value) => (dispatch) => {
    apiService.getRegions(value)
        .then(res => {
            dispatch(getRegionsAC(res));
        })
}
export const getCitiesThunk = (region, value) => (dispatch) => {
    apiService.getCities(region, value)
        .then(res => {
            dispatch(getCitiesAC(res));
        })
}
export const getWarehouseThunk = (city, value) => (dispatch) => {
    apiService.getWarehouses(city, value)
        .then(res => {
            dispatch(getWarehousesAC(res));
        })
}
export const getSpecialtyDataThunk = () => (dispatch) => {
    apiService.getSpecialty()
        .then(res => {
            dispatch(getSpecialtyDataAC(res));
        })
}
export const checkPromocodeOrderThunk = (promocode) => (dispatch, getState) => {
    const user_id = getState().auth.user.id;
    debugger
    apiService.checkPromocode(promocode, user_id)
        .then(res => {
            if(res && res.discount) {
                debugger
                dispatch(changeOrderFormData('promocodeDiscount', res.discount))
            } else {
                debugger
                dispatch(changeOrderFormData('promocodeDiscount', null))
            }
        });
}
export const setManufacturersDataThunk = () => (dispatch) => {
    apiService.getManufacturers()
        .then(res => {
            dispatch(setManufacturersDataAC(res))
        });
}
export const sendAccountContactMessageThunk = (theme, message, user) => (dispatch) => {
    apiService.sendContactAccount(theme, message, user)
        .then(res => {
            dispatch(changeAccountContactFormAC('theme', ''));
            dispatch(changeAccountContactFormAC('message', ''));
            dispatch(changeAccountContactFormAC('isSend', true));
        });
}
export const setAllTranslatesThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusACS(true));
    apiService.getTranslates()
        .then(res => {
            if(res) {
                dispatch(setTranslatesAC(res));
            }
            dispatch(changeLoaderStatusACS(false));
        })
}
export const resetCustomerPasswordThunk = (email) => (dispatch) => {
    apiService.resetPassword(email)
        .then(res => {
            if(res.status) {
                dispatch(changePasswordResetValueEmailAC('email', null));
                dispatch(changePasswordResetValueEmailAC('success', true));
                dispatch(changePasswordResetValueEmailAC('fail', false));
                dispatch(changePasswordResetValueEmailAC('message', 'Лист з інструкціями по відновленню паролю успішно надісланий!'));
            } else {
                if(res.message) {
                    dispatch(changePasswordResetValueEmailAC('success', false));
                    dispatch(changePasswordResetValueEmailAC('fail', true));
                    dispatch(changePasswordResetValueEmailAC('message', res.message));
                } else {
                    dispatch(changePasswordResetValueEmailAC('success', false));
                    dispatch(changePasswordResetValueEmailAC('fail', true));
                    dispatch(changePasswordResetValueEmailAC('message', 'Щось пішло не так'));
                }
            }
        });
}
export const setCustomerPasswordThunk = (password, token) => (dispatch) => {
    apiService.setPassword(password, token)
        .then(res => {
            if(res.status) {
                dispatch(changePasswordResetValueAC('password', null));
                dispatch(changePasswordResetValueAC('check_password', null));
                dispatch(changePasswordResetValueAC('success', true));
                dispatch(changePasswordResetValueAC('fail', false));
                dispatch(changePasswordResetValueAC('message', 'Пароль успішно змінено! Спробуйте авторизуватись, користуючись новим паролем'));
            } else {
                dispatch(changePasswordResetValueAC('success', false));
                dispatch(changePasswordResetValueAC('fail', true));
                dispatch(changePasswordResetValueAC('message', 'Зміна паролю невдала'));
            }
        });
}
export const changePartnerFormValueAC = (input, value) => {
    return {
        type: CHANGE_PARTNER_ADD_FORM,
        input: input,
        value: value
    }
}
export const formatSum = (sum) => {
    let format = (new Intl.NumberFormat('ru-RU').format(Number(sum).toFixed(2))).replace(',', '.');
    return format && sum > 0 ? format : '0';
}

export default MainReducer;
