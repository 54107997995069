import {formatSum} from "../../../store/reducers/MainReducer";
import {Link} from "react-router-dom";
import React from "react";
import './CalculateWindow.css';
import LangLink from "../../Common/LangLink";

const OrderCalculateWindow = (props) => {
    return props.totals ? <>
        <div className={'confirm_total_container row'}>
            <div className={'confirm_total_block ' + (props.full ? '' : 'col-md-4')}>
                {props.asPartner ?
                    ''
                    : <>
                        {(!props.user || !props.user.franchisees) && <>
                            {props.discounts?.map((discountq) => (
                                <div className="confirm_discount_type from_product row">
                                    <div className="col-md-8"><span>{discountq.name}</span></div>
                                    <div className="col-md-4"><p>{Number(discountq.percent).toFixed(0)}% <span>(₴ {formatSum(discountq.amount)})</span></p></div>
                                </div>
                            ))}
                            {/* {props.discountCart &&
                            <div className="confirm_discount_type from_product row">
                                <div className="col-md-8"><span>{props.getTranslate('front_calculate','calculate_discount_for_count_product')}</span></div>
                                <div className="col-md-4"><p>{Number(props.discountCart.percent).toFixed(0)}% <span>(₴ {formatSum(props.discountCart.amount)})</span></p></div>
                            </div>
                            }
                            {props.discountPersonal &&
                            <div className="confirm_discount_type from_product row">
                                <div className="col-md-8"><span>{props.getTranslate('front_calculate','calculate_discount_person_customer')}</span></div>
                                <div className="col-md-4"><p>{Number(props.discountPersonal.percent).toFixed(0)}% <span>(₴ {formatSum(props.discountPersonal.amount)})</span></p></div>
                            </div>
                            } */}
                        </>}
                        {/* {props.discountPromocode &&
                        <div className="confirm_discount_type from_product row">
                            <div className="col-md-8"><span>{props.getTranslate('front_calculate','calculate_discount_promo_code')}</span></div>
                            <div className="col-md-4"><p>{Number(props.discountPromocode.percent).toFixed(0)}% <span>(₴ {formatSum(props.discountPromocode.amount)})</span></p></div>
                        </div>
                        } */}
                    </>
                }

                <div className="confirm_final_price row">
                    <div className="col-md-8">
                        <p>{props.getTranslate('front_calculate','calculate_amount_payment')}:</p>
                    </div>
                    <div className="col-md-4">
                        <span>₴ {props.asPartner ? 0 : formatSum(props.totals)}</span>
                    </div>
                </div>
                {props.user && Number(props.user.isPartner) ?
                    <div className="calculate_window_item_checkbox checkbox">
                        <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   checked={props.asPartner ? 'checked' : ''}
                                   onChange={(event) => props.changeOrderValue('asPartner', event.target.checked)}
                                   id={`calculate_window_as_partner_checkbox`}/>
                            <label className="custom-control-label"
                                   htmlFor={`calculate_window_as_partner_checkbox`}>{props.getTranslate('front_calculate','calculate_ordered_by_partner')}</label>
                        </div>
                    </div>
                    : ''
                }
                {props.full ? '' :
                    <div className="row">
                        <LangLink to={props.type === 'preorder' ? '/checkout/preorder' : '/checkout/order'} className={'col-md-12 confirm_button_payment'}>
                            <button>{props.type === 'preorder' ? props.getTranslate('front_calculate','calculate_ordered_preorder') : props.getTranslate('front_calculate','calculate_ordered_by_money')}</button>
                        </LangLink>
                    </div>
                }
                {props.type === 'preorder' &&
                    <div className="row">
                        <small>{props.getTranslate('front_calculate','calculate_info_price_change')}</small>
                    </div>
                }
                {!props.isAuth && props.type === 'preorder' ?
                    <div className="row">
                        <small>{props.getTranslate('front_calculate','calculate_info_preorder_access')}</small>
                    </div>
                    : ''
                }

            </div>
        </div>
    </> : '';
}

export default OrderCalculateWindow;