import React from 'react';
import {Link} from "react-router-dom";
import LangLink from "../Common/LangLink";
import ApiService from "../../api/api";

const apiService = new ApiService();

const renderMenuItem = (e) => {
    return (
        <div className="mbu_menu_link_container mbu_menu_container">
            <div className="mbu_menu_link">
                <LangLink to={'/category/' + e.alias}>{e.name}</LangLink>
            </div>
            {e.childs && e.childs.length > 0 && (
                <div className="mbu_menu_link_childrens">
                    {e.childs.map(c => {
                        return renderMenuItem(c);
                    })}
                </div>
            )}
        </div>
    );
};

const Menu = (props) => {
    const getPriceList = (event) => {
        event.preventDefault();
        const lang = localStorage.getItem('medbuy_store_main_config_lang');
        apiService.getPriceList(lang);
    }
    return (
        <>
            <nav>
                <div className="main_menu_container container">

                    <div className="menu_item menu_pages">
                        <LangLink className={'menu_item_title ' + (props.activeMenu === 'about' ? 'active' : '')}
                                  onClick={() => props.toggleMenu(props.activeMenu === 'about' ? null : 'about')}
                                  to={'/'}
                        >
                            <span><i className="fas fa-home"></i></span>
                        </LangLink>






                        <div className="menu_item menu_categories">
                            <div className={'menu_item_title ' + (props.activeMenu === 'category' ? 'active' : '')}
                                 onClick={() => props.toggleMenu(props.activeMenu === 'category' ? null : 'category')}>
                                {props.getTranslate('front_menu','menu_catalog_title')}
                            </div>
                            <div className="mbu_menu_links">
                                {props.items && props.items.map(e => {
                                    return renderMenuItem(e);
                                })}
                            </div>



                            <div className={'menu_item_mains_categories_container'}>
                                <div className={'menu_item_childrens_1 menu_item_childrens_main'}>
                                    <div className="menu_item_children">
                                        <div className={'menu_item_children_title childrens_1'}>
                                            <Link>{props.getTranslate('front_menu','menu_expres_test_title')}</Link>
                                        </div>
                                    </div>
                                    <div className={'menu_item_childrens_1'}>
                                        {props.items && props.items.filter((e) => e.alias !== 'ІFA_Іnfekciyni_zahvoryuvannya').map(e => {
                                            return (<div className="menu_item_children">
                                                <div className={'menu_item_children_title childrens_1'}>
                                                    <LangLink to={'/category/' + e.alias}>{e.name}</LangLink>
                                                </div>
                                                {e.childs && e.childs.length > 0 && <div className="menu_item_childrens_2">
                                                    {e.childs && e.childs.map(c => {
                                                        return <div className="menu_item_children">
                                                            <div className="menu_item_children_title">
                                                                <LangLink to={'/category/' + c.alias}>{c.name}</LangLink>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>}

                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>






                        <LangLink className={'menu_item_title ' + (props.activeMenu === 'partner_program' ? 'active' : '')}
                                  onClick={() => props.toggleMenu(props.activeMenu === 'partner_program' ? null : 'partner_program')}
                                  to={'/partner_program'}
                        >
                            <span>{props.getTranslate('front_menu','menu_partner_program_title')}</span>
                        </LangLink>
                        <LangLink className={'menu_item_title ' + (props.activeMenu === 'payment_information' ? 'active' : '')}
                                  onClick={() => props.toggleMenu(props.activeMenu === 'payment_information' ? null : 'about')}
                                  to={'/payment_information'}
                        >
                            <span>{props.getTranslate('front_menu','menu_delivery_title')}</span>
                        </LangLink>
                        <LangLink className={'menu_item_title ' + (props.activeMenu === 'about' ? 'active' : '')}
                                  onClick={() => props.toggleMenu(props.activeMenu === 'about' ? null : 'about')}
                                  to={'/about'}
                        >
                            <span>{props.getTranslate('front_menu','menu_about_us_title')}</span>
                        </LangLink>
                        <button title={"Прайс Лист"} className={"menu_item_title price_list_in_menu"} onClick={getPriceList}>{props.getTranslate('front_menu','menu_price_list_title')}</button>
                    </div>
                </div>
                <div className="main_menu_container container">
                    {/*<span className="main_menu_text_warning">Сміливість. Приймаємо, обробляємо та відправляємо Ваші замовлення 24/7. Але є один нюанс - повітряна тривога.</span>*/}
                </div>
            </nav>
            {/*<div className="row header-top-bar">*/}
            {/*    Шановні клієнти, звертаємо вашу увагу на тимчасову відсутність технічної можливості оплати замовлень картою на нашому сайті. Ми вже працюємо над вирішенням проблеми і найближчим часом така можливість буде відновлена. Наразі просимо при розміщенні замовлення обирати оплату безготівковим переказом. Такий переказ можна виконати в переважній більшості банківських додатків. Будь ласка, зв'яжіться з нами, якщо вам потрібна допомога в проведенні такої безготівкової оплати.*/}
            {/*</div>*/}
        </>

    )
}

export default Menu;