import React from 'react';

class ApiService extends React.Component {

    //TODO: ссылка под замену (АПИ)

    _baseUrl = 'https://api.medbuy.online/api';
    _baseUrlGlobal = 'https://dev-global.medbuy.world/api';
    // _baseUrlGlobal = 'http://localhost:9001/api';

    async _getData(url, method, data, headers = {}, resType = 'json') {

        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': '12345',
                'Language': localStorage.getItem('medbuy_store_main_config_lang'),
                ...headers
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        if (method === 'POST') {
            options.body = JSON.stringify(data);
        }
        const res = await fetch(`${this._baseUrl}${url}`, options);

        if (resType === 'json') {
            body = await res.json();
        }
        if (resType === 'text') {
            body = await res.text();
        }

        if (!res.count > 0) {

        }

        return body;

    }

    async _getDataGlobal(url, method, data, headers = {}, resType = 'json') {

        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Language': localStorage.getItem('medbuy_store_main_config_lang'),
                ...headers
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        if (method === 'POST') {
            options.body = JSON.stringify(data);
        }
        const res = await fetch(`${this._baseUrlGlobal}${url}`, options);

        if (resType === 'json') {
            body = await res.json();
        }
        if (resType === 'text') {
            body = await res.text();
        }

        if (!res.count > 0) {

        }

        return body;

    }

    getMenuItems = async () => {
        return [
            {
                id: 1,
                name: 'Інфекційні захворювання',
                alias: 'infections',
                parents: []
            },
            {
                id: 2,
                name: 'Біохімія',
                alias: 'biohimic',
                parents: [
                    {
                        id: 3,
                        name: 'Біохімія крові',
                        alias: 'biohimic_blood',
                        parents: []
                    },
                    {
                        id: 4,
                        name: 'Біохімія сечі',
                        alias: 'biohimic_secha',
                        parents: []
                    }
                ]
            },
            {
                id: 5,
                name: 'Гематологія',
                alias: 'gematology',
                parents: []
            },
        ];
    }

    getProduct = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/product/${id}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformProduct(res.data);
        } else {
            return false;
        }
    }

    _transformProduct = (data) => {
        return {
            id: data.id,
            name: data.name,
            manufacturer: data.id_manufacturer,
            manufacturer_name: data.manufacturer_name,
            long_name: data.long_name,
            analyte: data.analyte,
            description: data.description,
            model: data.model,
            // rates: data.rate,
            alias: data.alias,
            count: data.count,
            price: data.discount ? data?.discount : data?.price,
            in_pack: data.in_pack,
            price_one: data?.price_one_discount ? data?.price_one_discount : data.price_one,
            documents: data.documents ? data.documents : [],
            old_price_one: data?.price_one_discount ? data?.price_one : null,
            old_price: data.discount ? data.price : null,
            discount_percent: data.discount_percent ? (data.discount_percent / 100) : null,
            images: data.images ? data.images.map(e => {
                return {
                    id: e.id,
                    link: e.link,
                    isMain: Number(e.is_main) > 0
                }
            }) : [],
            tags: data.tags ? data.tags.map((tag) => ({
                alias: tag.tag.alias,
                id: tag.tag.id,
                name: tag.tag?.info?.[0]?.value,
            })) : null,
            recommend_products: data.recommend,
            seo: {
                title: data.meta_title,
                description: data.meta_description,
                keywords: data.meta_keywords
            },
            options: data.attributes ? data.attributes.map(e => {
                return {
                    id: e?.attribute_value?.id,
                    sort: e?.attribute?.sort,
                    name: e?.attribute?.info?.[0]?.value,
                    value: e?.attribute_value?.info?.[0]?.value,
                }
            }) : []
        }
    }

    _transformProducts = (data) => {
        return data && data.filter(e => Number(e.is_active)).map(e => {
            let image = e.images && e.images.filter(i => Number(i.is_main))[0];
            return {
                id: e.id,
                name: e?.name ?? '',
                price: e?.discount ? parseInt(e?.discount) : parseInt(e.price),
                price_one: e?.price_one_discount ? Number(parseInt(e?.price_one_discount)) : (e.price_one === e.price ? null : parseInt(e.price_one)),
                old_price_one: e.price_one_discount ? String(parseInt(e.price_one)) : null,
                old_price: e.discount ? parseInt(e?.price) : null,
                model: e.model,
                in_pack: e.in_pack,
                alias: e.alias,
                new_product: e.is_new,
                manufacturer_name: e.manufacturer_name,
                discount_percent: e.discount_percent ? (e.discount_percent / 100) : null,
                count: e?.count,
                image: image && image.link,
                options: e.attributes ? e.attributes.map(e => {
                    if (e) {
                        return {
                            id: e?.attribute_value?.id,
                            sort: e?.attribute?.sort,
                            name: e?.attribute?.info?.[0]?.value,
                            value: e?.attribute_value?.info?.[0]?.value,
                        }
                    }
                }) : []
            }
        })
    }

    getProducts = async () => {
        const res = await this._getDataGlobal(
            `/store/products?page=1&limit=9`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.rows),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getProductsHomeRelated = async () => {
        const res = await this._getDataGlobal(
            `/store/related`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data),
                count: res.data?.length
            };
        } else {
            return false;
        }
    }

    getProductsCategory = async (category, page = 1) => {
        const res = await this._getDataGlobal(
            `/store/products?page=${page ?? 1}&limit=9&categories=alias,${category}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.rows),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getCategoryTree = async () => {
        const res = await this._getDataGlobal(
            `/store/categories`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    _getProductsFilterRequest = (filters) => {
        let req = '';
        
        if (filters?.price_min && filters?.price_max) {
            req += `&price=${filters?.price_min},${filters?.price_max}`
        }
        if (filters?.manufacturer?.length) {
            req += `&id_manufacturer=${filters?.manufacturer?.join(',')}`
        }
        if (filters?.in_pack?.length) {
            req += `&in_pack=${filters?.in_pack?.join(',')}`
        }
        if (filters?.getDiscount?.length) {
            req += `&discount=id_product,1`
        }
        if (filters?.inStock?.length === 1) {
            req += `&warehouse_product_lot=count,${filters?.inStock?.join(',')}`
        }
        if (filters?.isNewProduct?.length) {
            req += `&is_new=${filters?.isNewProduct?.join(',')}`
        }
        if (filters?.attributes?.length) {
            req += `&product_attributes=id,${filters?.attributes?.reduce((acc, attribute) => {
                acc += attribute.id + '_' + attribute.value + ';';
                return acc;
            }, '')}`
        }
        if (filters?.category) {
            req += `&categories=alias,${filters?.category}`
        }
        
        return req;
    }

    getProductsFiltered = async (filters, page = 1) => {
        const res = await this._getDataGlobal(
            `/store/products?page=${page}&limit=9${this._getProductsFilterRequest(filters)}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.rows),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getProductsSearch = async (key, filters = {}, page = 1) => {
        const res = await this._getDataGlobal(
            `/store/products/search?page=${page ?? 1}&limit=9${this._getProductsFilterRequest(filters)}&param_search=${key}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.rows),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getManufacturers = async () => {
        const res = await this._getDataGlobal(
            `/manufacturer?page=1&limit=50`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res?.data?.rows) {
            return res?.data?.rows?.map((manufacturer) => ({ ...manufacturer, date: manufacturer.createdAt }));
        } else {
            return false;
        }
    }

    getLanguages = async () => {
        const res = await this._getDataGlobal(
            `/store/languages`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getRegions = async () => {
        const res = await this._getDataGlobal(
            `/directory/regions`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getCities = async (region, value) => {
        const res = await this._getDataGlobal(
            `/directory/cities?region=${region}${value ? '&city=' + value : ''}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getWarehouses = async (city, value) => {
        const res = await this._getDataGlobal(
            `/directory/warehouses-points?city=${city}${value ? '&warehouse=' + value: ''}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getDiscounts = async (products, id_customer, promocode, is_partner) => {
        const res = await this._getDataGlobal(
            `/store/order-discounts`,
            'POST',
            {
                products,
                id_customer,
                promocode,
                is_partner
            },
            {},
            'json'
        );
        if (res && res.data) {
            return res.data
        } else {
            return false;
        }
    }

    checkEmail = async (email) => {
        const res = await this._getDataGlobal(
            `/store/check-email`,
            'POST',
            {
                email: email
            },
            {},
            'json'
        );
        if (res && res.data && res.data.check) {
            return false
        } else {
            return true;
        }
    }

    sendOrder = async (order, products, type = 1, user_id) => {
        const res = await this._getDataGlobal(
            `/store/order`,
            'POST',
            {
                ...order,
                // city: order.city ? order.city.value : '',
                // district: order.district ? order.district.value : '',
                // region: order.region ? order.region.value : '',
                products: products.map((product) => {
                    product.description = '';
                    product.recommend_products = [];
                    return product;
                }),
                type: type,
                id_customer: user_id
            },
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    sendContactAccount = async (theme, message, user) => {
        const res = await this._getDataGlobal(
            `/store/contact-form`,
            'POST',
            {
                theme: theme,
                message: message,
                user: user
            },
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getTranslates = async () => {
        const res = await this._getDataGlobal(
            `/store/translate?lang=${localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    createTranslate = async (translate) => {
        return;
        // const res = await this._getData(
        //     `/translates`,
        //     'POST',
        //     {
        //         ...translate
        //     },
        //     {"Point-Action": "create"},
        //     'json'
        // );
        // if (res && res.data) {
        //     return res.data;
        // } else {
        //     return false;
        // }
    }

    getFilters = async (type, data) => {
        const res = await this._getDataGlobal(
            `/store/filters`,
            'POST',
            {...data},
            {},
            'json'
        );
        if (res && res.data) {
            return {
                price: {
                    max: res.data.maxPrice,
                    min: res.data.minPrice
                },
                attributes: res.data?.attributes?.map((attribute) => ({
                    id: attribute.id,
                    name: attribute?.info?.find((language) => String(language.id_language) === String(localStorage.getItem('medbuy_store_main_config_lang') ?? 1))?.value,
                    values: attribute?.attribute_values?.map((attribute_value) => ({
                        id: attribute_value.id,
                        value: attribute_value?.info?.find((language) => String(language.id_language) === String(localStorage.getItem('medbuy_store_main_config_lang') ?? 1))?.value
                    }))

                }))
            }
        } else {
            return false;
        }
    }

    getPriceList = async (lang) => {
        window.open(this._baseUrlGlobal + `/store/price-list?lang=${lang}`)
        // const res = await this._getDataGlobal(
        //     `/store/price-list`,
        //     'POST',
        //     {
        //         lang: lang
        //     },
        //     {},
        //     'json'
        // );
        // if (res && res.data && res.data.link) {
        //    window.open(res.data.link);
        // } else {
        //     return false;
        // }
    }

    checkPromocode = async (promocode, user_id) => {
        const res = await this._getDataGlobal(
            `/store/check-promo-code`,
            'POST',
            {
                promocode: promocode,
                user_id: user_id
            },
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getAuth = async (login, pass) => {
        if (!login || !pass) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/auth`,
            'POST',
            {email: login, password: pass},
            {},
            'json'
        );

        if (res && res.data && res.status && Number(res.status) === 1) {
            return res.data.token;
        } else if(res && Number(res.status) === 0) {
            switch (res.message) {
                case 'Password or login error':
                    return false;
                case 'Client not active':
                    window.location.href = '/account/activate/' + res.data.token;
                    break;
                default:
                    return false;
            }
        } else {
            return false;
        }
    };

    resetPassword = async (email) => {
        if (!email) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/reset-password`,
            'POST',
            {email: email},
            {},
            'json'
        );
        if (res && Number(res.status) === 1) {
            return {
                status: true
            };
        } else if(res && res.message === 'Customer not found') {
            return {
                status: false,
                message: 'Емейл не знайдено в базі даних'
            }
        } else {
            return {
                status: false
            };
        }
    };

    setPassword = async (password, token) => {

        if (!password || !token) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/set-password`,
            'POST',
            {
                password: password,
                token: token
            },
            {},
            'json'
        );
        if (res && res.data) {
            return {
                status: true
            };
        } else {
            return {
                status: false
            };
        }
    };

    getAuthData = async (token = localStorage.getItem('medbuy_store_user_token')) => {
        if (!token) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/auth-data`,
            'POST',
            {token: token},
            {},
            'json'
        );
        if (res && res.data) {
            return this._transformAuth(res.data);
        } else {
            return false;
        }
    };

    _transformAuth = (data) => {
        return {
            id: data.customer.id,
            name: data.customer.info.name,
            email: data.customer.info.email,
            phone: data.customer.info.phone,
            token: data.customer.token,
            isPartner: data.customer.partner,
            companies: data.companies,
            discount: null,
            partner_products : data.partner_products ? data.partner_products.map(pp => {
                return {
                    name: pp.name,
                    model: pp.model,
                    count: pp.count,
                    manufacturer: pp.id_manufacturer,
                    alias: pp.alias,
                    price: pp.price,
                    price_one: pp.price_one,
                    // lot: pp.lot,
                    // date_to: pp.date_to,
                }
            }) : [],
            partner_products_sale : data.partner_product_sale ? data.partner_product_sale.map(pps => {
                return {
                    alias: pps.alias,
                    name: pps.name,
                    count: pps.count,
                    price: pps.price,
                    price_one: pps.price,
                    bonus: pps.bonus,
                    date: pps.date,
                }
            }) : [],
            // address: data.address ? data.address.map(ad => {
            //     return {
            //         id: ad.id,
            //         id_customer: ad.id_customer,
            //         region: ad.region,
            //         city: ad.city,
            //         district: ad.district,
            //         street: ad.street,
            //         number_build: ad.number_build,
            //         date: ad.date,
            //     }
            // }) : [],
            address: [],
            partner_bonus: data.partner_bonus ? data.partner_bonus.map(bo => {
                return {
                    bonus: bo.bonus,
                    status: bo.status,
                    date: bo.createdAt,
                }
            }) : [],
            // partner_info : data.partner_info ? data.partner_info.map(pi => {
            //                 return {
            //                     rang: pi.rang,
            //             //         // ipn: pi.ipn,
            //                     bonus: pi.bonus,
            //             //         // product_sale: pi.product_sale,
            //             //         // specialty: pi.specialty,
            //             //         // address_register: pi.address_register,
            //             //         // pasport_series: pi.pasport_series,
            //             //         // pasport_organization: pi.pasport_organization,
            //             //         // pasport_data: pi.pasport_data,
            //             //         // bank_name: pi.bank_name,
            //             //         // bank_code: pi.bank_code,
            //             //         // bank_iban: pi.bank_iban
            //                 }
            //             }) : [],
            partner_info : data.rang && Number(data.rang) > 0 ? [{
                    rang: data.rang,
                    bonus: data.bonus,
            }] : [],
            id_customer: data.customer.id,
            middle_name: data.customer.info.middle_name,
            // orders: data.orders ? data.orders.map(o => {
            //     return {
            //         comment: o.comment,
            //         customer_email: o.customer_email,
            //         customer_middle_name: o.customer_middle_name,
            //         customer_name: o.customer_name,
            //         customer_surname: o.customer_surname,
            //         date: o.date,
            //         discount: o.discount,
            //         district: o.district,
            //         city: o.city,
            //         np_warehouse: o.np_warehouse,
            //         id: o.id,
            //         id_customer: o.id_customer,
            //         id_delivery_type: o.id_delivery_type,
            //         id_sell_manager: o.id_sell_manager,
            //         parent: o.parent,
            //         payment: o.payment,
            //         products: o.products ? o.products.map(p => {
            //             return {
            //                 count: p.count,
            //                 id: p.id,
            //                 manufacturer: p.info.manufacturer,
            //                 model: p.info.model,
            //                 name: p.info.name,
            //                 alias: p.info.alias,
            //                 price: p.price
            //             }
            //         }) : [],
            //         payments: o.payments ? o.payments.map(pa => {
            //             return {
            //                 invoice_number: pa.invoice_number,
            //                 payment_code: pa.payment_code,
            //                 status: pa.status,
            //                 date: pa.date,
            //             }
            //         }) : [],
            //         region: o.region,
            //         status: o.status,
            //         street: o.street,
            //         street_house: o.street_house,
            //         total: o.total,
            //         type: o.type,
            //         documents: o.documents ? o.documents.map(doc => {
            //             return {
            //                 id: doc.id,
            //                 id_order: doc.id_order,
            //                 type: doc.type,
            //                 link: doc.link,
            //                 date: doc.date
            //             }
            //         }) : [],
            //     }
            // }) : [],
            orders: [],
            surname: data.customer.info.surname,
            franchisees: null
        }
    }

    registrationCustomer = async (form) => {
        if (!form) {
            return false;
        }
        const res = await this._getDataGlobal(
            `/store/register-customer`,
            'POST',
            {...form},
            {},
            'json'
        );
        if (res && res.data && res.data.id && res.data.token) {
            return res.data.token;
        } else {
            return false;
        }
    };

    setCookie = async (value, id_customer) => {

        return true;

        // const res = await this._getData(
        //     `/customers`,
        //     'POST',
        //     {
        //         value: Boolean(value) ? 1 : 0,
        //         id_customer: id_customer
        //     },
        //     {'Point-Action': 'add_cookie'},
        //     'json'
        // );
        // if (res && res.data && res.data.status) {
        //     return true;
        // } else {
        //     return false;
        // }
    };

    checkCookie = async (id_customer) => {

        return true;

        // const res = await this._getData(
        //     `/customers`,
        //     'POST',
        //     {
        //         id_customer: id_customer
        //     },
        //     {'Point-Action': 'check_cookie'},
        //     'json'
        // );
        // if (res && res.data && res.data.check) {
        //     return true;
        // } else {
        //     return false;
        // }
    };

    updateUser = async (user) => {
        const res = await this._getDataGlobal(
            `/store/customer/${user.id}/update-info`,
            'POST',
            {
                ...user
            },
            {},
            'json'
        );
        if(res && res.status) {
            return true;
        } else {
            return false;
        }
    };

    getCustomersOrders = async (id_customer) => {

        if(!id_customer) return false;

        const res = await this._getDataGlobal(
            `/store/customer/${id_customer}/order`,
            'GET',
            {},
            {},
            'json'
        );
        if(res && res.data) {
            return this._transformCustomerOrders(res.data);
        } else {
            return false;
        }
    }

    _transformCustomerOrders = (orders) => {
       return orders ? orders.map(o => {
            return {
                comment: o.comment,
                customer_email: o.customer_email,
                customer_middle_name: o.customer_middle_name,
                customer_name: o.customer_name,
                customer_surname: o.customer_surname,
                date: o.createdAt,
                discount: o.discount,
                district: o.district,
                city: o.city,
                np_warehouse: o.np_warehouse,
                id: o.id,
                id_customer: o.id_customer,
                id_delivery_type: o.id_delivery_type,
                id_sell_manager: o.id_sell_manager,
                parent: o.parent,
                payment: o.payment,
                products: o.products ? o.products.map(p => {
                    return {
                        count: p.count,
                        id: p.id_product,
                        manufacturer: p.id_manufacturer,
                        model: p.model,
                        name: p.name,
                        alias: p.alias,
                        price: p.price
                    }
                }) : [],
                payments: o.payments ? o.payments.map(pa => {
                    return {
                        invoice_number: pa.invoice_number,
                        payment_code: pa.payment_code,
                        status: pa.status,
                        date: pa.date,
                    }
                }) : [],
                region: o.region,
                status: o.id_status,
                street: o.street,
                street_house: o.street_house,
                total: o.total,
                type: o.is_partner ? 3 : 1,
                documents: o.documents ? o.documents.map(doc => {
                    return {
                        id: doc.id,
                        id_order: doc.id_order,
                        type: doc.type,
                        link: doc.link,
                        date: doc.date
                    }
                }) : [],
            }
        }) : []
    }

    getDirectory = async () => {
        const res = await this._getDataGlobal(
            `/store/directory`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
             return this._transformDirectory(res.data);
        } else {
            return false;
        }
    }
    _transformDirectory = (data) => {
        return {
            // regions: data.regions ? data.regions.map(o => {
            //     return {
            //         id: o.id,
            //         name: o.name,
            //         date: o.date
            //     }
            // }) : [],
            // districts: data.districts ? data.districts.map(d => {
            //     return {
            //         id: d.id,
            //         id_region: d.id_region,
            //         name: d.name,
            //         date: d.date
            //     }
            // }) : [],
            // cities: data.cities ? data.cities.map(c => {
            //     return {
            //         id: c.id,
            //         id_region: c.id_region,
            //         name: c.name,
            //         date: c.date
            //     }
            // }) : [],
            // np_warehouse: data.warehouses ? data.warehouses.map(c => {
            //     return {
            //         id: c.id,
            //         id_city: c.id_city,
            //         name: c.name,
            //         number: c.number,
            //         address: c.address,
            //         date: c.date
            //     }
            // }) : [],
            // currencies: data.currencies ? data.currencies.map(cr => {
            //     return {
            //         id: cr.id,
            //         name: cr.name,
            //         amount: cr.amount,
            //         code: cr.code,
            //         text: cr.text,
            //         date: cr.date
            //     }
            // }) : [],
            // categories: data.categories ? data.categories.map(cr => {
            //     return {
            //         id: cr.id,
            //         name: cr.value,
            //     }
            // }) : [],
            // regions: [],
            districts: [],
            // cities: [],
            // np_warehouse: [],
            currencies: [],
            categories: [],
            status: data.status ? data.status.map(s => {
                return {
                    id: s.id,
                    id_order_status: s.id,
                    lang: s.info.id_language,
                    value: s.info.value,
                    date: s.createdAt
                }
            }) : [],
            in_pack: data.in_pack ? data.in_pack : [],
            specialty: data.specialty ? data.specialty.map(sp => {
                return {
                    id: sp.id,
                    name: sp.name,
                    date: sp.createdAt
                }
            }) : [],
            customer_partner_rang: data.customer_partner_rang ? data.customer_partner_rang.map(cpr => {
                return {
                    id: cpr.id,
                    name: cpr.info[0].value,
                    bonus_rate: cpr.bonus_rate,
                    must_sale: cpr.must_sale,
                    date: cpr.createdAt
                }
            }) : [],
        }
    }

    getSpecialty = async () => {
        const res = await this._getData(
            `/specialty`,
            'GET',
            {},
            {"Point-Action": "specialty"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getQRC = async (customer, products) => {
        const res = await this._getDataGlobal(
            `/store/qr-code`,
            'POST',
            {
                customer: customer,
                products: products
            },
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    createPartner = async (partner) => {
        const res = await this._getDataGlobal(
            `/store/register-partner`,
            'POST',
            {...partner},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    createPartnerBonus = async (bonus, id_customer) => {

        if(!bonus || !id_customer) return false;

        const res = await this._getData(
            `/store/partner/${id_customer}/bonus`,
            'POST',
            {
                bonus: bonus
            },
            {},
            'json'
        );
        if (res && res.data && res.data.status) {
            return res.data.link;
        } else {
            return false;
        }
    }

    getWFPHash = async (data) => {
        const res = await this._getDataGlobal(
            `/store/order-hash-wfp`,
            'POST',
            {...data},
            {},
            'json'
        );
        debugger;
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
}

    export
    default
    ApiService;
