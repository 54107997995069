import React from 'react';
import {connect} from "react-redux";
import './CalculateWindow.css';
import CalculateWindow from "./CalculateWindow";
import {changeOrderFormData, setCartDiscountsDataAC, setCartTotalsAC} from "../../../store/reducers/MainReducer";
import {checkAuthDataThunk} from "../../../store/reducers/AuthReducer";
import ApiService from "../../../api/api";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class CalculateWindowContainer extends React.Component {

    constructor(props){
        super(props);
        this.getTotals = this.getTotals.bind(this);
        this.apiService = new ApiService();
        this.state = {
            total: null,
            discounts: [],
        }
    }

    getTotals(products, user, promocode, is_partner) {
        this.apiService.getDiscounts(products, user?.id, promocode, is_partner).then((res) => {
            this.setState({
                total: res.total.toFixed(0),
                discounts: res.discounts.filter((discountf) => discountf?.amount > 0) 
            });
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(
            (JSON.stringify(nextProps.products) !== JSON.stringify(this.props.products)) ||
            (JSON.stringify(nextProps.productsInStock) !== JSON.stringify(this.props.productsInStock)) ||
            (JSON.stringify(nextProps.productsPreOrder) !== JSON.stringify(this.props.productsPreOrder)) ||
            (nextProps.promocode !== this.props.promocode) ||
            (JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))
        ) {
            this.getTotals(nextProps.products, nextProps.user, nextProps.promocode, nextProps.formOrder.asPartner);
        }
    }

    componentDidMount() {
        this.getTotals(this.props.products, this.props.user, this.props.promocode, this.props.formOrder.asPartner);
    }

    render() {
        if (!this.state.total) return null;
        return <CalculateWindow totals={this.state.total}
                                type={this.props.type}
                                discounts={this.state.discounts}
                                changeOrderValue={this.props.changeOrderValue}
                                asPartner={this.props.formOrder.asPartner}
                                user={this.props.user}
                                full={this.props.full}
                                isAuth={this.props.isAuth}
                                getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        productsInStock: state.main.cart.products,
        productsPreOrder: state.main.cart.productsPreOrder,
        promocode: state.main.order.promocodeDiscount,
        isAuth: state.auth.isAuth,
        formOrder: state.main.order,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setDiscounts: (discounts) => dispatch(setCartDiscountsDataAC(discounts)),
        setTotals: (totals) => dispatch(setCartTotalsAC(totals)),
        getAuth: () => dispatch(checkAuthDataThunk()),
        changeOrderValue: (input, value) => dispatch(changeOrderFormData(input, value)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(CalculateWindowContainer);